import React, { useRef, useState } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'

import FormRenderer from 'components/common/FormRenderer'

import useAuth from 'hooks/context/useAuth'
import resolveErrorText from 'helpers/resolveErrorText'

function ResetPassword() {
  const [loading, setLoading] = useState(false)
  //const [success, setSuccess] = useState(false)
  const formikRef = useRef(null)
  const { resetTempPasswordChallenge } = useAuth()

  const handleSubmit = async (values, { setErrors }) => {
    setLoading(true)

    resetTempPasswordChallenge(values).catch((err) => {
      setErrors({
        submit: resolveErrorText(err),
      })

      setLoading(false)
    })
  }

  const renderingData = [
    {
      field: 'current_password',
      display: 'Current password',
      type: 'password',
    },
    {
      field: 'new_password',
      display: 'New password',
      type: 'password',
    },
    {
      field: 'new_password_confirm',
      display: 'Confirm new password',
      type: 'password',
    },
  ]

  const footerActionProps = [
    {
      children: 'Submit',
      color: 'primary',
      variant: 'contained',
      onClick: () => formikRef?.current?.submitForm(),
      disabled: loading,
    },
  ]

  return (
    <Box sx={{ width: '100%' }}>
      <Typography component="h1" variant="h1" mb={4}>
        Reset Temporary Password
      </Typography>
      {/* {success ? (
        <Alert severity="success">
          Your password has been successfully reset.
        </Alert>
      ) : (
        
      )}
      {!success && ( */}
      {formikRef?.current?.errors?.submit && (
        <Alert severity="warning">{formikRef.current.errors.submit}</Alert>
      )}
      <FormRenderer
        renderingData={renderingData}
        footerActionProps={footerActionProps}
        innerRef={formikRef}
        handleSubmit={handleSubmit}
      />
      {/* )} */}
    </Box>
  )
}

export default ResetPassword
