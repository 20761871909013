import React, { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import isValidToken from 'helpers/auth/isValidToken'

import useAuth from 'hooks/context/useAuth'
function AuthGuard({ children }) {
  const location = useLocation()
  const [renderData, setRenderData] = useState(<div></div>)

  let { token, challenge, isAuthenticated, isInitialized, refreshAuthToken } =
    useAuth()

  useEffect(() => {
    let newRenderData = (() => {
      if (!isInitialized) {
        return <div />
      }

      // if user unauthenticated, redirect to login
      if (!token?.accessToken) {
        return <Navigate to="/console/login" replace />
      } else if (!isValidToken(token)) {
        return <div />
      } else if (
        isAuthenticated &&
        location.pathname.startsWith('/console/login')
      ) {
        return <Navigate to="/console/dashboard" replace />
      }

      // if user logged in but requires 2FA, redirect to correct challenge
      if (
        challenge === 'MFA_AUTH' &&
        location.pathname !== '/console/login/challenge/auth-code'
      ) {
        return <Navigate to="/console/login/challenge/auth-code" replace />
      }
      if (
        challenge === 'RESET_TEMP_PASSWORD' &&
        location.pathname !== '/console/login/challenge/reset-password'
      ) {
        return <Navigate to="/console/login/challenge/reset-password" replace />
      }

      if (
        challenge === 'MFA_INITIALIZE' &&
        location.pathname !== '/console/login/challenge/mfa-initialize'
      ) {
        return <Navigate to="/console/login/challenge/mfa-initialize" replace />
      }
      return <>{children}</>
    })()
    setRenderData(newRenderData)
  }, [
    refreshAuthToken,
    token,
    challenge,
    children,
    location,
    isAuthenticated,
    isInitialized,
  ])

  return renderData
}

export default AuthGuard
