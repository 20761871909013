import React, { useEffect, useState, useRef } from 'react'
import { Formik } from 'formik'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import { Link } from 'react-router-dom'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FlashFeedback from 'components/common/FlashFeedback'

import FieldGroup from 'components/common/FormRenderer/FieldGroup'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import resolveErrorText from 'helpers/resolveErrorText'

//import useClient from 'hooks/useClient'
import useClientContext from 'hooks/context/useClientContext'

import { format, parseISO } from 'date-fns'

const initialValues = {
  client_name: '',
  client_fein: '',
  client_is_prospect: false,
  client_is_active: true,
  client_address: {
    client_address_line_1: '',
    client_address_line_2: '',
    client_address_city: '',
    client_address_state: '',
    client_address_zip: '',
  },
  'client_address[client_address_line_1]': '',
  'client_address[client_address_line_2]': '',
  'client_address[client_address_city]': '',
  'client_address[client_address_state]': '',
  'client_address[client_address_zip]': ''
}

function ClientDetail() {
  const { client, clientUpdate, clientContactDelete } = useClientContext()
  const formikRef = useRef(null)
  const [clientFormData, setClientFormData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [updatingClient, setUpdatingClient] = useState(false)
  const [error, setError] = useState(false)
  const [addressDisplay, setAddressDisplay] = useState([])
  const [contactDisplay, setContactDisplay] = useState([])
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const [editClientFormOpen, setEditClientFormOpen] = useState(false)
  const [addClientContactOpen, setAddClientContactOpen] = useState(false)

  const resetEditClientForm = () => {
    formikRef.current.resetForm()
  }

  const [tzStr] = useState(
    <>
      &nbsp;
      {
        new Date()
          .toLocaleTimeString('en-us', {
            timeZoneName: 'short',
          })
          .split(' ')[2]
      }
    </>
  )

  const setUpClientForForm = (dbClient) => {
    let clientData = {
      client_name: dbClient.client_name,
      client_fein: dbClient.client_fein || '',
      client_is_prospect: dbClient.client_is_prospect,
      client_is_active: dbClient.client_is_active,
      client_address: {
        client_address_line_1:
          dbClient?.client_address?.client_address_line_1 || '',
        client_address_line_2:
          dbClient?.client_address?.client_address_line_2 || '',
        client_address_city:
          dbClient?.client_address?.client_address_city || '',
        client_address_state:
          dbClient?.client_address?.client_address_state || '',
        client_address_zip: dbClient?.client_address?.client_address_zip || '',
      },
      'client_address[client_address_line_1]': '',
      'client_address[client_address_line_2]': '',
      'client_address[client_address_city]': '',
      'client_address[client_address_state]': '',
      'client_address[client_address_zip]': '',
      client_contacts: []
    }
    if (dbClient.client_address) {
      let addressSet = []
      if (dbClient.client_address.client_address_line_1) {
        addressSet.push(dbClient.client_address.client_address_line_1)
        clientData['client_address[client_address_line_1]'] =
          dbClient.client_address.client_address_line_1
      }
      if (dbClient.client_address.client_address_line_2) {
        addressSet.push(dbClient.client_address.client_address_line_2)
        clientData['client_address[client_address_line_2]'] =
          dbClient.client_address.client_address_line_2
      }

      if (
        dbClient.client_address.client_address_city ||
        dbClient.client_address.client_address_state ||
        dbClient.client_address.client_address_zip
      ) {
        addressSet.push(
          `${
            dbClient.client_address.client_address_city
              ? dbClient.client_address.client_address_city + ', '
              : ''
          }${dbClient.client_address.client_address_state} ${
            dbClient.client_address.client_address_zip
          }`
        )
      }

      clientData['client_address[client_address_city]'] =
        dbClient.client_address.client_address_city
      clientData['client_address[client_address_state]'] =
        dbClient.client_address.client_address_state
      clientData['client_address[client_address_zip]'] =
        dbClient.client_address.client_address_zip
      setAddressDisplay(addressSet)
    }
    if (dbClient.client_contacts) {
      let contactsSet = []
      dbClient.client_contacts.forEach(function(contact, i) {
        clientData.client_contacts.push({
          client_contact_id: contact.client_contact_id,
          client_contact_name: contact.client_contact_name || '',
          client_contact_title: contact.client_contact_title || '',
          client_contact_office_num: contact.client_contact_office_num || '',
          client_contact_phone: contact.client_contact_phone || '',
          client_contact_email: contact.client_contact_email || ''
        })
        let contactSet = []
        contactSet.push("Contact " + (i + 1))
        if (contact.client_contact_name) {
          contactSet.push(contact.client_contact_name)
          clientData['client_contacts[' + i + '][client_contact_name]'] =
            contact.client_contact_name
        }
        if (contact.client_contact_title) {
          contactSet.push(contact.client_contact_title)
          clientData['client_contacts[' + i + '][client_contact_title]'] =
            contact.client_contact_title
        }
        if (contact.client_contact_office_num) {
          contactSet.push('O: ' + contact.client_contact_office_num)
          clientData['client_contacts[' + i + '][client_contact_office_num]'] =
            contact.client_contact_office_num
        }
        if (contact.client_contact_phone) {
          contactSet.push('M: ' + contact.client_contact_phone)
          clientData['client_contacts[' + i + '][client_contact_phone]'] =
            contact.client_contact_phone
        }
        if (contact.client_contact_email) {
          contactSet.push(contact.client_contact_email)
          clientData['client_contacts[' + i + '][client_contact_email]'] =
            contact.client_contact_email
        }
        contactsSet.push(contactSet)
      })
      setContactDisplay(contactsSet)
    }
    setClientFormData(clientData)
  }

  useEffect(() => {
    setLoading(true)
    try {
      setUpClientForForm(client)
    } catch (err) {
      setError(resolveErrorText(err))
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client])

  const toggleAddClientContact = () => {
    setUpClientForForm(client)
    if (addClientContactOpen) {
      // reset fields for new contact
      const idx = client.client_contacts.length
      formikRef.current.setFieldValue('client_contacts[' + idx + ']', null)
    }
    setAddClientContactOpen(!addClientContactOpen)
  }

  const deleteClientContact = async (idx) => {
    if (idx === client.client_contacts.length) {
      // deleting the new contact (not yet saved)
      toggleAddClientContact()
    } else {
      try {
        setUpdatingClient(true)
        const deleteID = client.client_contacts[idx].client_contact_id
        await clientContactDelete(deleteID)
        setFeedbackOpen(true)
        setEditClientFormOpen(false)
        setAddClientContactOpen(false)
      } finally {
        setUpdatingClient(false)
      }
    }
  }

  const handleSubmit = async (values, { setErrors }) => {
    try {
      setUpdatingClient(true)
      let fein = (values.client_fein?.trim() || '').replaceAll('-', '')
      if (
        !client.client_fein !== !fein &&
        (!fein || fein.length !== 9 || isNaN(fein))
      ) {
        throw new Error(
          `FEIN ${!fein ? 'cannot be deleted and ' : ''}must be 9 digits long`
        )
      }
      // validate contacts
      values.client_contacts.forEach(function(contact) {
        contact.client_contact_name = (contact.client_contact_name || '').trim()
        contact.client_contact_title = (contact.client_contact_title || '').trim()
        contact.client_contact_office_num = (contact.client_contact_office_num || '').trim()
        contact.client_contact_phone = (contact.client_contact_phone || '').trim()
        contact.client_contact_email = (contact.client_contact_email || '').trim()
        if (!(contact.client_contact_name || contact.client_contact_title ||
          contact.client_contact_office_num || contact.client_contact_phone ||
          contact.client_contact_email))
        {
          throw new Error('Contacts cannot have all fields empty')
        }
      })
      const newContact = values.client_contacts[values.client_contacts.length - 1]
      if (newContact && !newContact.client_contact_id) {
        // is a new contact; indicate by setting ID to 0
        newContact.client_contact_id = 0
      }
      let payload = {
        client_name: values.client_name,
        client_fein: fein || '',
        client_is_prospect: values.client_is_prospect,
        client_is_active: values.client_is_active,
        client_address: {
          client_address_line_1: (
            values?.client_address?.client_address_line_1 || ''
          ).trim(),
          client_address_line_2: (
            values?.client_address?.client_address_line_2 || ''
          ).trim(),
          client_address_city: (
            values?.client_address?.client_address_city || ''
          ).trim(),
          client_address_state: (
            values?.client_address?.client_address_state || ''
          ).trim(),
          client_address_zip: (
            values?.client_address?.client_address_zip || ''
          ).trim(),
        },
        client_contacts: values.client_contacts
      }
      await clientUpdate(payload)
      setErrors({ submit: '' })
      setFeedbackOpen(true)
      setEditClientFormOpen(false)
      setAddClientContactOpen(false)
    } catch (err) {
      setErrors({ submit: resolveErrorText(err) })
    } finally {
      setUpdatingClient(false)
    }
  }

  return (
    <Box>
      <FlashFeedback
        open={feedbackOpen}
        severity={'success'}
        setOpen={setFeedbackOpen}
        message={'Client Updated Successfully'}
      />
      <Paper
        sx={{ display: 'flex', flexDirection: 'column', padding: '0.5rem' }}
      >
        {error && <Alert severity="warning">{error}</Alert>}
        <Typography component="h1" variant="h1" mb={4}>
          Client Details
        </Typography>
        {loading ? (
          <Typography component="h2" variant="h2">
            Loading
          </Typography>
        ) : client ? (
          <>
            <Table
              sx={{ minWidth: 700, mb: 4, p: 1 }}
              aria-label="customized table"
            >
              <TableBody>
                <TableRow>
                  <TableCell>Name:</TableCell>
                  <TableCell>{client.client_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>FEIN:</TableCell>
                  <TableCell>
                    {!client.client_fein
                      ? 'N/A'
                      : `${client.client_fein.substring(
                          0,
                          2
                        )}-${client.client_fein.substring(2, 9)}`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Is Prospect?</TableCell>
                  <TableCell>
                    {client.client_is_prospect ? 'Yes' : 'No'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Is Active?</TableCell>
                  <TableCell>
                    {client.client_is_active ? 'Yes' : 'No'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Address:</TableCell>
                  <TableCell>
                    {addressDisplay.map((line, idx) => (
                      <React.Fragment key={idx}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Contacts:</TableCell>
                  <TableCell>
                    {contactDisplay.map((contact, idx) => (
                      <Box key={idx} sx={{ pt: idx === 0 ? 0 : 2 }}>
                      {contact.map((line, idx) => (
                        <Box key={idx} sx={{ fontWeight: idx === 0 ? 'bold' : '' }}>
                          {line}
                        </Box>
                      ))}
                      </Box>
                    ))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Updated:</TableCell>
                  <TableCell>
                    {client.client_modified_datetime ? (
                      <>
                        {format(
                          parseISO(client.client_modified_datetime),
                          'M/d/y h:mm a'
                        )}
                        {tzStr}
                      </>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Created:</TableCell>
                  <TableCell>
                    {client.client_created_datetime ? (
                      <>
                        {format(
                          parseISO(client.client_created_datetime),
                          'M/d/y h:mm a'
                        )}
                        {tzStr}
                      </>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Notes:</TableCell>
                  <TableCell>
                    <Button
                      component={Link}
                      to={`/console/clients/${client.client_id}/notes`}
                      color="primary"
                      variant="contained"
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {clientFormData && (
              <Accordion
                mb={2}
                expanded={editClientFormOpen}
                sx={{ border: '1px solid black' }}
              >
                <AccordionSummary
                  onClick={() => {
                    if (editClientFormOpen) {
                      resetEditClientForm()
                    }
                    setEditClientFormOpen(!editClientFormOpen)
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography component="h3" variant="h3">
                    Modify Client
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Formik
                    enableReinitialize
                    initialValues={clientFormData || initialValues}
                    onSubmit={handleSubmit}
                    innerRef={formikRef}
                    validateOnChange={false}
                    p={2}
                  >
                    <Stack p={1}>
                      {formikRef?.current?.errors?.submit && (
                        <Alert severity="warning">
                          {formikRef?.current?.errors?.submit}
                        </Alert>
                      )}
                      <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} sm={4}>
                          <FieldGroup
                            fieldData={{
                              field: 'client_name',
                              display: 'Client Name',
                              type: 'text',
                              InputProps: {
                                disabled: loading || updatingClient,
                                required: true,
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FieldGroup
                            fieldData={{
                              field: 'client_fein',
                              display: 'Client FEIN',
                              type: 'text',
                              InputProps: {
                                disabled: loading || updatingClient,
                                required: true,
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <FieldGroup
                            fieldData={{
                              field: 'client_is_prospect',
                              display: 'Is Prospect',
                              type: 'select',
                              fieldType: 'select',
                              options: [
                                {
                                  value: true,
                                  display: 'Yes',
                                },
                                {
                                  value: false,
                                  display: 'No',
                                },
                              ],
                              fullWidth: true,
                              InputProps: {
                                required: true,
                                disabled: loading || updatingClient,
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <FieldGroup
                            fieldData={{
                              field: 'client_is_active',
                              display: 'Is Active',
                              type: 'select',
                              fieldType: 'select',
                              options: [
                                {
                                  value: true,
                                  display: 'Yes',
                                },
                                {
                                  value: false,
                                  display: 'No',
                                },
                              ],
                              fullWidth: true,
                              InputProps: {
                                required: true,
                                disabled: loading || updatingClient,
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FieldGroup
                            fieldData={{
                              field: 'client_address[client_address_line_1]',
                              display: 'Address Line 1',
                              type: 'text',
                              InputProps: {
                                disabled: loading || updatingClient,
                                required: true,
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FieldGroup
                            fieldData={{
                              field: 'client_address[client_address_line_2]',
                              display: 'Address Line 2',
                              type: 'text',
                              InputProps: {
                                disabled: loading || updatingClient,
                                required: false,
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FieldGroup
                            fieldData={{
                              field: 'client_address[client_address_city]',
                              display: 'City',
                              type: 'text',
                              InputProps: {
                                disabled: loading || updatingClient,
                                required: true,
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FieldGroup
                            fieldData={{
                              field: 'client_address[client_address_state]',
                              display: 'State',
                              type: 'text',
                              InputProps: {
                                disabled: loading || updatingClient,
                                required: true,
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FieldGroup
                            fieldData={{
                              field: 'client_address[client_address_zip]',
                              display: 'Zip Code',
                              type: 'text',
                              InputProps: {
                                disabled: loading || updatingClient,
                                required: true,
                              },
                            }}
                          />
                        </Grid>
                        {(addClientContactOpen ? client.client_contacts.concat(null) :
                        client.client_contacts).map((_, idx) => (
                          <Grid container key={idx} spacing={2} mt={0} ml={0}>
                            <Grid item xs={12} mt={4}>
                              Contact {idx + 1}
                              <Button
                                disabled={loading || updatingClient}
                                fullWidth={false}
                                color="error"
                                variant="contained"
                                sx={{ float: 'right' }}
                                onClick={() => deleteClientContact(idx)}
                              >
                                Delete
                              </Button>
                            </Grid>
                            <Grid item xs={12}>
                              <FieldGroup
                                fieldData={{
                                  field: 'client_contacts[' + idx + '][client_contact_name]',
                                  display: 'Contact Name',
                                  type: 'text',
                                  InputProps: {
                                    disabled: loading || updatingClient,
                                    required: false,
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FieldGroup
                                fieldData={{
                                  field: 'client_contacts[' + idx + '][client_contact_title]',
                                  display: 'Contact Title',
                                  type: 'text',
                                  InputProps: {
                                    disabled: loading || updatingClient,
                                    required: false,
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FieldGroup
                                fieldData={{
                                  field: 'client_contacts[' + idx + '][client_contact_office_num]',
                                  display: 'Contact Office #',
                                  type: 'text',
                                  InputProps: {
                                    disabled: loading || updatingClient,
                                    required: false,
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FieldGroup
                                fieldData={{
                                  field: 'client_contacts[' + idx + '][client_contact_phone]',
                                  display: 'Contact Mobile #',
                                  type: 'text',
                                  InputProps: {
                                    disabled: loading || updatingClient,
                                    required: false,
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FieldGroup
                                fieldData={{
                                  field: 'client_contacts[' + idx + '][client_contact_email]',
                                  display: 'Contact Email',
                                  type: 'text',
                                  InputProps: {
                                    disabled: loading || updatingClient,
                                    required: false,
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        ))}
                        <Grid item xs={12}>
                          <Button
                            disabled={loading || updatingClient || addClientContactOpen}
                            fullWidth={true}
                            color="primary"
                            variant="contained"
                            onClick={toggleAddClientContact}
                          >
                            Add Client Contact
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            disabled={loading || updatingClient}
                            fullWidth={true}
                            color="primary"
                            variant="contained"
                            onClick={() => formikRef?.current?.submitForm()}
                          >
                            Update Client
                          </Button>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Formik>
                </AccordionDetails>
              </Accordion>
            )}
          </>
        ) : null}
      </Paper>
    </Box>
  )
}

export default ClientDetail
