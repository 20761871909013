import React, { useEffect, useState } from 'react'

import TextField from '@mui/material/TextField'

function AsyncTextField({ value, onChange, ...props }) {
  const [localValue, setLocalValue] = useState(value)
  const [typingTimeout, setTypingTimeout] = useState(null)

  const handleChange = (e) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }

    setLocalValue(e.target.value)

    const newTypingTimeout = setTimeout(() => {
      onChange(e)
    }, 100)

    setTypingTimeout(newTypingTimeout)
  }

  useEffect(() => {
    setLocalValue(value)
  }, [value])
  return (
    <TextField
      {...props}
      value={localValue}
      onChange={handleChange}
      onBlur={onChange}
    />
  )
}

export default AsyncTextField
