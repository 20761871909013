import React, { useRef, useState } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import FormRenderer from 'components/common/FormRenderer'
import resolveErrorText from 'helpers/resolveErrorText'

import useAuth from 'hooks/context/useAuth'

function MFACode({ setFrame, frame }) {
  const formikRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const { enterMFACode, setupMFA } = useAuth()

  const handleBack = () => {
    setFrame(0)
  }

  const handleSubmit = async (values, { setStatus }) => {
    if (!loading) {
      setLoading(true)
      if (setFrame) {
        setupMFA({ ...values }).catch((err) => {
          setStatus({
            errors: { submit: resolveErrorText(err) },
          })
          setLoading(false)
        })
      } else if (!setFrame || frame === 1) {
        enterMFACode({
          ...values,
        }).catch((err) => {
          setStatus({
            errors: { submit: resolveErrorText(err) },
          })
          setLoading(false)
        })
      }
    }
  }

  const renderingData = [
    {
      field: 'code',
      display: 'Authenticator code',
      type: 'number',
    },
  ]

  const footerActionProps = [
    {
      children: 'Submit',
      color: 'primary',
      variant: 'contained',
      disabled: loading,
      onClick: () => formikRef?.current?.submitForm(),
    },
    {
      children: 'Back',
      color: 'primary',
      variant: 'outlined',
      onClick: handleBack,
    },
  ]

  if (!setFrame) {
    delete footerActionProps[1]
  }

  return (
    <Box>
      <Typography component="h1" variant="h1">
        2-Step Verification
      </Typography>
      <Typography mb={4}>Enter the code from your authenticator app</Typography>
      <FormRenderer
        renderingData={renderingData}
        footerActionProps={footerActionProps}
        innerRef={formikRef}
        handleSubmit={handleSubmit}
      />
    </Box>
  )
}

export default MFACode
