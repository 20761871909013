import React, { useRef, useState } from 'react'
import { Formik } from 'formik'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'

import Stack from '@mui/material/Stack'

import FieldGroup from 'components/common/FormRenderer/FieldGroup'

import useUser from 'hooks/useUser'
import resolveErrorText from 'helpers/resolveErrorText'

const initialValues = {
  user_id: null,
  user_first_name: '',
  user_last_name: '',
  user_email: '',
  user_is_active: true,
  user_is_admin: false,
}

function UserCreateEditForm({
  user,
  userIsSelf,
  editingUserIsAdmin,
  submitSuccessCallback,
}) {
  const {
    user_id,
    user_first_name,
    user_is_active,
    user_is_admin,
    user_last_name,
    user_email,
  } = user || initialValues

  const submitCallback = submitSuccessCallback || null

  const defaultValues = {
    user_first_name,
    user_last_name,
    user_email,
    user_is_active: user_is_active ? 'Yes' : 'No',
    user_is_admin: user_is_admin ? 'Yes' : 'No',
  }
  const formikRef = useRef(null)
  const { userUpdate, userCreate } = useUser()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (values, { setErrors }) => {
    try {
      setLoading(true)
      let payload = {
        user_first_name: values.user_first_name,
        user_last_name: values.user_last_name,
      }
      if (user_id && editingUserIsAdmin && !userIsSelf) {
        payload.user_is_active = values.user_is_active === 'Yes'
        payload.user_is_admin = values.user_is_admin === 'Yes'
      }
      // if user_id is supplied, it's user update, otherwise, user create
      if (user_id) {
        payload.user_id = user_id
        await userUpdate(payload)
      } else {
        payload.user_email = values.user_email
        await userCreate(payload)
      }

      if (submitCallback) {
        submitCallback()
      }
      if (!user_id) {
        // reset create form - clear out
        formikRef.current.resetForm()
      }
    } catch (err) {
      setErrors({ submit: resolveErrorText(err) })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={handleSubmit}
      innerRef={formikRef}
      validateOnChange={false}
      p={2}
    >
      <Stack p={1}>
        {formikRef?.current?.errors?.submit && (
          <Alert severity="warning">{formikRef?.current?.errors?.submit}</Alert>
        )}
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={6}>
            <FieldGroup
              fieldData={{
                field: 'user_first_name',
                display: 'First Name',
                type: 'text',
                InputProps: {
                  disabled: loading,
                  required: true,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FieldGroup
              fieldData={{
                field: 'user_last_name',
                display: 'Last Name',
                type: 'text',
                InputProps: {
                  disabled: loading,
                  required: true,
                },
              }}
            />
          </Grid>
          {user_id ? (
            !userIsSelf &&
            editingUserIsAdmin && (
              <>
                <Grid item xs={12}>
                  <FieldGroup
                    fieldData={{
                      field: 'user_is_active',
                      type: 'radio',
                      display: 'User Is Active?',
                      options: [
                        {
                          value: 'Yes',
                          display: 'Yes',
                        },
                        {
                          value: 'No',
                          display: 'No',
                        },
                      ],
                      InputProps: {
                        disabled: loading,
                        required: true,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldGroup
                    fieldData={{
                      field: 'user_is_admin',
                      type: 'radio',
                      display: 'User Role',
                      options: [
                        {
                          value: 'Yes',
                          display: 'Admin',
                        },
                        {
                          value: 'No',
                          display: 'Basic',
                        },
                      ],
                      InputProps: {
                        disabled: loading,
                        required: true,
                      },
                    }}
                  />
                </Grid>
              </>
            )
          ) : (
            <Grid item xs={12}>
              <FieldGroup
                fieldData={{
                  field: 'user_email',
                  display: 'Email',
                  type: 'email',
                  InputProps: {
                    disabled: loading,
                    required: true,
                  },
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              disabled={loading}
              fullWidth={true}
              color="primary"
              variant="contained"
              onClick={() => formikRef?.current?.submitForm()}
            >
              {user_id ? 'Update' : 'Create'}&nbsp;User
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </Formik>
  )
}

export default UserCreateEditForm
