import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import Box from '@mui/material/Box'
import MuiListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PeopleIcon from '@mui/icons-material/People'
import NoteIcon from '@mui/icons-material/Note'
import PolicyIcon from '@mui/icons-material/Policy'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import EditIcon from '@mui/icons-material/Edit'

const subsectionHeaderStyles = {
  width: '100%',
  maxWidth: '100%',
  textAlign: 'center',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  overflowX: 'hidden',
}

function ListItem({ to, ...props }) {
  const location = useLocation()

  const selected = location.pathname === to

  return (
    <MuiListItem
      button
      component={Link}
      to={to}
      sx={{ bgcolor: selected && 'rgba(0, 0, 0, 0.04)' }}
      {...props}
    />
  )
}

export const mainListItems = (
  <Box>
    <ListItem to="/console/dashboard">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem>
  </Box>
)

export const userListItems = (
  <Box>
    <ListSubheader inset>User</ListSubheader>

    <ListItem to="/console/users">
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="User Overview" />
    </ListItem>
  </Box>
)

export const clientListItems = (client) => (
  <Box>
    <ListSubheader
      sx={subsectionHeaderStyles}
      component={Link}
      to={`/console/clients/${client.client_id}`}
    >
      Client "{client.client_name}"
    </ListSubheader>
    <ListSubheader inset>
      FEIN:{' '}
      {!client.client_fein
        ? 'N/A'
        : `${client.client_fein.substring(0, 2)}-${client.client_fein.substring(
            2,
            9
          )}`}
    </ListSubheader>

    <ListItem to={`/console/clients/${client.client_id}/notes`}>
      <ListItemIcon>
        <NoteIcon />
      </ListItemIcon>
      <ListItemText primary="Notes" />
    </ListItem>
    <ListItem to={`/console/clients/${client.client_id}/policies`}>
      <ListItemIcon>
        <PolicyIcon />
      </ListItemIcon>
      <ListItemText primary="Policies" />
    </ListItem>
  </Box>
)

export const clientPolicyListItems = (client, clientPolicy) => (
  <Box>
    <ListSubheader
      sx={subsectionHeaderStyles}
      component={Link}
      to={`/console/clients/${client.client_id}/policies/${clientPolicy.client_policy_id}`}
    >
      Policy #{clientPolicy.client_policy_number}
    </ListSubheader>
    <ListItem
      to={`/console/clients/${client.client_id}/policies/${clientPolicy.client_policy_id}/edit`}
    >
      <ListItemIcon>
        <EditIcon />
      </ListItemIcon>
      <ListItemText primary="Edit Policy" />
    </ListItem>
    <ListItem
      to={`/console/clients/${client.client_id}/policies/${clientPolicy.client_policy_id}/worklogs`}
    >
      <ListItemIcon>
        <PendingActionsIcon />
      </ListItemIcon>
      <ListItemText primary="Work Logs" />
    </ListItem>
  </Box>
)
