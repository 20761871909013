import React from 'react'
import { Outlet } from 'react-router-dom'

import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'

import Header from './Header'
import Footer from 'components/layout/Footer'

function PublicFacing({ children }) {
  return (
    <>
      <CssBaseline />
      <Header />
      <Box component="main" sx={{ minHeight: '90vh' }}>
        <Outlet />
      </Box>
      {children}
      <Footer />
    </>
  )
}

export default PublicFacing
