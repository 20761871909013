const resolveErrorText = (err) => {
  if (!err) {
    return 'An error has occured'
  }
  let errMessage = err?.message || 'An error has occured'
  if (err?.response) {
    // resolve potential error via response. otherwise, display error message, or if not set, simply say default data
    if (err.response.data?.message) {
      errMessage = err.response.data.message
    } else if (err.response.data && typeof err.response.data === 'string') {
      errMessage = err.response.data
    } else if (err.response.status === 401 && err.response.statusText) {
      errMessage = err.response.statusText
    }
  }
  return errMessage
}

export default resolveErrorText
