import React from 'react'
import { useFormikContext } from 'formik'

import MenuItem from '@mui/material/MenuItem'

import AsyncTextField from 'components/common/AsyncTextField'

import RadioField from './RadioField'
import AutocompleteField from './AutocompleteField'
import ScrollableTermsField from './ScrollableTermsField'
import DropzoneField from './Dropzone'
import DateField from './DateField'
import PhoneField from './PhoneField'

function Field({ fieldData, parentData }) {
  const { values, handleChange, errors } = useFormikContext()
  const {
    field,
    display,
    fieldType,
    options,
    type,
    inputProps,
    hide,
    callback,
    InputProps,
    InputLabelProps,
  } = fieldData

  const handleUpdateChange = ({ name, value }) => {
    handleChange({ target: { name, value } })

    if (callback) {
      callback(value, { values, handleChange })
    }
  }

  if (hide && hide(values[parentData.field])) {
    return <></>
  }

  if (type === 'radio') {
    return <RadioField fieldData={fieldData} />
  }

  if (type === 'autocomplete') {
    return <AutocompleteField fieldData={fieldData} />
  }

  if (type === 'terms') {
    return <ScrollableTermsField fieldData={fieldData} />
  }

  if (type === 'fileupload') {
    return <DropzoneField fieldData={fieldData} />
  }

  if (type === 'date') {
    return <DateField fieldData={fieldData} />
  }

  if (type === 'phone') {
    return <PhoneField fieldData={fieldData} />
  }

  return (
    <AsyncTextField
      {...inputProps}
      InputProps={InputProps}
      type={type}
      id={field}
      name={field}
      label={display}
      value={
        values[field] === undefined || values[field] === null
          ? ''
          : values[field]
      }
      onChange={(e) =>
        handleUpdateChange({ name: field, value: e.target.value })
      }
      select={fieldType === 'select'}
      fullWidth
      InputLabelProps={{
        ...InputLabelProps,
        shrink: values[field] ? true : undefined,
      }}
      error={Boolean(errors[field])}
      helperText={errors[field]}
    >
      {options?.map((option, idx) => (
        <MenuItem key={idx} value={option.value}>
          {option.display}
        </MenuItem>
      ))}
    </AsyncTextField>
  )
}

export default Field
