import React from 'react'
import { Navigate } from 'react-router-dom'

import { useParams } from 'react-router-dom'
import useClientPolicyContext from 'hooks/context/useClientPolicyContext'
import useClientContext from 'hooks/context/useClientContext'

// must come after client guard
function ClientPolicyGuard({ children }) {
  let {
    isInitialized: clientPolicyContextInit,
    clientPolicyLoaded,
    clientPolicyExists,
    contextClientPolicyID,
    clientPolicy,
  } = useClientPolicyContext()
  let {
    isInitialized: clientContextInit,
    clientIsLoaded,
    clientExists,
    contextClientID,
  } = useClientContext()
  const { clientPolicyId } = useParams()

  if (!clientContextInit) {
    return <div />
  } else if (clientIsLoaded && (!clientExists || !contextClientID)) {
    return <Navigate to="/console/dashboard" replace />
  }
  let fallbackURL = '/console/clients/' + contextClientID + '/policies'
  const clientPolicyID = parseInt(clientPolicyId, 10)
  if (isNaN(clientPolicyID) || clientPolicyID <= 0) {
    return <Navigate to={fallbackURL} replace />
  }

  if (!clientPolicyContextInit) {
    return <div />
  }

  if (!clientPolicyLoaded) {
    return <div />
  }

  if (
    clientPolicyLoaded &&
    !clientPolicyExists &&
    ((isNaN(contextClientPolicyID) && contextClientPolicyID !== null) ||
      contextClientPolicyID === clientPolicyID)
  ) {
    return <Navigate to={fallbackURL} replace />
  }
  if (contextClientPolicyID !== clientPolicyID) {
    return <div />
  }
  if (clientPolicy.client_id !== contextClientID) {
    // this is not this client's policy
    return <Navigate to={fallbackURL} replace />
  }

  return <>{children}</>
}

export default ClientPolicyGuard
