const typography = {
  fontFamily: ['Montserrat', 'sans serif'].join(','),
  fontSize: 14,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  lineHeight: '16px',
  h1: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 'bold',
  },
  h2: {
    fontSize: 20,
    lineHeight: '24px',
  },
  h3: {
    fontSize: 16,
    lineHeight: '16px',
  },
  button: {
    textTransform: 'none',
  },
}

export default typography
