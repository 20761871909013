import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { useIMask } from 'react-imask'

import TextField from '@mui/material/TextField'

function PhoneField({ fieldData }) {
  const [opts] = useState({
    mask: '(#00) 000-0000',
    definitions: {
      '#': /[1-9]/,
    },
  })
  const { ref } = useIMask(opts)
  const { values, handleChange, errors } = useFormikContext()
  const { field, display, inputProps } = fieldData

  return (
    <TextField
      {...inputProps}
      type="tel"
      id={field}
      name={field}
      label={display}
      value={values[field] || ''}
      onChange={(e) =>
        handleChange({ target: { name: field, value: e.target.value } })
      }
      fullWidth
      InputLabelProps={{ shrink: values[field] ? true : undefined }}
      error={Boolean(errors[field])}
      helperText={errors[field]}
      inputRef={ref}
    />
  )
}

export default PhoneField
