import { useContext } from 'react'
import { ClientContext } from 'contexts/ClientContext'

// wrapper for ClientContext
const useClientContext = () => {
  const context = useContext(ClientContext)

  if (!context) {
    throw new Error('ClientContext must be placed within ClientProvider')
  }

  return context
}

export default useClientContext
