import React from 'react'
import { Outlet } from 'react-router-dom'

import Box from '@mui/material/Box'

function Login({ children }) {
  return (
    <Box
      sx={{ my: 'auto', display: 'flex', textAlign: 'center', width: '100%' }}
    >
      <Outlet />
      {children}
    </Box>
  )
}

export default Login
