import React from 'react'
import { Link } from 'react-router-dom'

import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'

function Header() {
  return (
    <>
      <AppBar color="background">
        <Toolbar>
          <img
            src="/static/imgs/consultech-insurance.png"
            alt="Consultech Insurance"
            style={{
              height: 'auto',
              width: 'auto',
              maxHeight: '50px',
              maxWidth: '200px',
              overflow: 'hidden',
            }}
          />
          <Box
            sx={{
              textAlign: 'right',
              width: '100%',
              '& > .MuiButton-root': { mr: 4 },
            }}
          >
            <Button
              color="primary"
              variant="contained"
              LinkComponent={Link}
              to="/console/login"
            >
              Login
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{ height: '64px' }} />
    </>
  )
}

export default Header
