import React, { useReducer, createContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import axios from 'utils/axios'

const initialState = {
  isInitialized: false,
  clientPolicyLoaded: false,
  clientPolicyExists: false,
  contextClientPolicyID: null,
  clientPolicy: {
    client_policy_id: null,
    client_policy_number: '',
    client_policy_effective_datetime: null,
    client_policy_expiration_datetime: null,
    client_policy_modified_datetime: null,
    client_policy_created_datetime: null,
  },
}

const storeState = (state) => {
  // exclude defined vars from local storage
  const { isInitialized, ...rest } = state ?? {}
  if (state?.client_policy_id) {
    localStorage.clientPolicyState = JSON.stringify(rest)
  } else {
    delete localStorage.clientPolicyState
  }
}

const ClientPolicyReducer = (state, action) => {
  switch (action.type) {
    case 'CLIENT_POLICY_INTIALIZE':
      return {
        ...state,
        isInitialized: true,
        clientPolicy: action.payload.clientPolicy,
        clientPolicyExists:
          action.payload.clientPolicyExists || initialState.clientPolicyExists,
        clientPolicyLoaded:
          action.payload.clientPolicyLoaded || initialState.clientPolicyLoaded,
        contextClientPolicyID:
          action.payload.clientPolicy?.client_policy_id ||
          action.payload.client_policy_id ||
          initialState.contextClientPolicyID,
      }
    case 'CLIENT_POLICY_PURGE':
      delete localStorage.clientPolicyState
      return {
        ...initialState,
        isInitialized: true,
        clientPolicyExists: false,
        clientPolicyLoaded: action?.payload?.contextClientPolicyID
          ? true
          : false,
        contextClientPolicyID: action?.payload?.contextClientPolicyID || null,
      }
    case 'UPDATE_CLIENT_POLICY':
      const { clientPolicy } = action.payload
      return {
        ...state,
        clientPolicy: clientPolicy,
        contextClientPolicyID: clientPolicy.client_policy_id,
        clientPolicyLoaded: true,
        clientPolicyExists: true,
      }
    case 'STORE_STATE': {
      storeState(state)
      return state
    }
    default:
      return state
  }
}

const ClientPolicyContext = createContext(null)

function ClientPolicyProvider({ children }) {
  const [state, dispatch] = useReducer(ClientPolicyReducer, initialState)
  const { clientPolicyId } = useParams()
  const [clientPolicyID, setClientPolicyID] = useState(null)

  useEffect(() => {
    if (!clientPolicyId) {
      setClientPolicyID(null)
      return
    }
    let clientPolicyIDInt = parseInt(clientPolicyId)
    if (!isNaN(clientPolicyIDInt) && clientPolicyIDInt <= 0) {
      clientPolicyIDInt = NaN
    }
    if (clientPolicyID !== clientPolicyIDInt) {
      setClientPolicyID(clientPolicyIDInt)
    }
  }, [clientPolicyId, clientPolicyID])

  const updateClientPolicyContext = async ({ clientPolicy }) => {
    if (!state?.isInitialized) {
      return
    } else if (clientPolicyID === null || isNaN(clientPolicyID)) {
      dispatch({
        type: 'CLIENT_POLICY_PURGE',
      })
      return
    } else if (
      clientPolicy?.client_policy_id &&
      clientPolicy?.client_policy_id !== clientPolicyID
    ) {
      throw new Error('Cannot shift clientPolicy context id via update')
    }
    // established that update information is in parity, and the state has been initialized
    try {
      if (
        clientPolicyID === state?.contextClientPolicyID &&
        state?.clientPolicyLoaded
      ) {
        if (state.clientPolicyExists) {
          if (clientPolicy?.client_policy_id) {
            dispatch({
              type: 'UPDATE_CLIENT_POLICY',
              payload: {
                clientPolicy,
              },
            })
          }
        } else {
          dispatch({
            type: 'CLIENT_POLICY_PURGE',
            payload: {
              contextClientPolicyID: clientPolicyID,
            },
          })
        }
        return
      } else {
        dispatch({
          type: 'CLIENT_POLICY_INTIALIZE',
          payload: {
            ...state,
          },
        })
      }

      const res = await axios
        .get('/client/policy/get', {
          params: { clientPolicyID: clientPolicyID },
        })
        .catch(async (errOrPromise) => {
          if (errOrPromise instanceof Error) {
            throw errOrPromise
          } else if (errOrPromise instanceof Promise) {
            return errOrPromise
          }
        })
      if (res.status === 200) {
        dispatch({
          type: 'UPDATE_CLIENT_POLICY',
          payload: {
            ...state,
            clientPolicy: res.data,
          },
        })
        return
      }
    } catch (err) {
      console.log(err)
    }
    dispatch({
      type: 'CLIENT_POLICY_PURGE',
      payload: {
        contextClientPolicyID: clientPolicyID,
      },
    })
  }

  const clientPolicyUpdate = async (payload) => {
    if (!state?.isInitialized) {
      throw new Error('No Client Policy In Context')
    } else if (
      state?.clientPolicy?.client_policy_id !== parseInt(clientPolicyId, 10)
    ) {
      throw new Error('Cannot issue update for policy out of context')
    }
    if (!payload) {
      throw new Error('ClientPolicy update requires a payload')
    }
    if (!payload.client_policy_id) {
      payload.client_policy_id = state.clientPolicy.client_policy_id
    } else if (
      payload.client_policy_id !== state.clientPolicy.client_policy_id
    ) {
      throw new Error('Cannot update policy that is not currently in context')
    }
    const res = await axios
      .post('/client/policy/update', payload)
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })
    // higher error codes do throw an error
    if (res.status !== 200) {
      throw new Error('Failed to update clientPolicy: ' + res.statusText)
    }
    await updateClientPolicyContext({ clientPolicy: res.data })
    return res.data
  }

  const clientPolicyWorklogSearch = async (
    createdDatetime,
    createdDatetimeAfter,
    createdDatetimeBefore,
    sort,
    sortOrder,
    limit,
    offset
  ) => {
    if (
      !state?.isInitialized ||
      !state?.clientPolicyExists ||
      !state.clientPolicy?.client_policy_id
    ) {
      return null
    }

    let searchTerms = {
      client_policy_id: state.clientPolicy.client_policy_id,
    }
    if (createdDatetime) {
      searchTerms.client_policy_worklog_created_datetime = createdDatetime
    } else {
      if (createdDatetimeAfter) {
        searchTerms.client_policy_worklog_created_datetime_after =
          createdDatetimeAfter
      }
      if (createdDatetimeBefore) {
        searchTerms.client_policy_worklog_created_datetime_before =
          createdDatetimeBefore
      }
    }
    if (sort && typeof sort === 'object' && Object.keys(sort).length > 0) {
      searchTerms.sort = sort
    }
    if (Array.isArray(sortOrder) && sortOrder.length > 0) {
      searchTerms.sort_order = sortOrder
    }
    // 0 = no limit
    if (!isNaN(limit) && parseInt(limit, 10) >= 0) {
      searchTerms.limit = limit
    }
    if (!isNaN(offset) && parseInt(offset, 10) >= 0) {
      searchTerms.offset = offset
    }

    const res = await axios
      .post('/client/policy/worklog/infoSearch', searchTerms)
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })
    // higher error codes do throw an error
    if (res.status !== 200) {
      throw new Error('Failed to load policy info: ' + res.statusText)
    }
    return res.data
  }

  const clientPolicyWorklogCreate = async (description) => {
    if (
      !state?.isInitialized ||
      !state?.clientPolicyExists ||
      !state.clientPolicy?.client_policy_id
    ) {
      throw new Error('No policy in context')
    }
    let payload = {
      client_policy_id: state.clientPolicy.client_policy_id,
      client_policy_worklog_description: description,
    }

    const res = await axios
      .post('/client/policy/worklog/create', payload)
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })
    if (res.status !== 200) {
      throw new Error(
        'Failed to create new client policy worklog: ' + res.statusText
      )
    }
    return true
  }

  const clientPolicyDocumentSearch = async (
    clientPolicyDocumentFolder,
    sort,
    sortOrder,
    limit,
    offset
  ) => {
    if (
      !state?.isInitialized ||
      !state?.clientPolicyExists ||
      !state.clientPolicy?.client_policy_id
    ) {
      throw new Error('No policy in context')
    }
    let searchTerms = {
      client_policy_id: state.clientPolicy.client_policy_id,
    }
    if (
      typeof clientPolicyDocumentFolder === 'string' &&
      clientPolicyDocumentFolder.length > 0
    ) {
      searchTerms.client_policy_document_folder = clientPolicyDocumentFolder
    }
    if (sort && typeof sort === 'object' && Object.keys(sort).length > 0) {
      searchTerms.sort = sort
    }
    if (Array.isArray(sortOrder) && sortOrder.length > 0) {
      searchTerms.sort_order = sortOrder
    }
    // 0 = no limit
    if (!isNaN(limit) && parseInt(limit, 10) >= 0) {
      searchTerms.limit = limit
    }
    if (!isNaN(offset) && parseInt(offset, 10) >= 0) {
      searchTerms.offset = offset
    }
    const res = await axios
      .post(`/client/policy/document/search`, searchTerms)
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })
    if (res.status !== 200) {
      throw new Error(res.statusText)
    }
    return res.data
  }

  const clientPolicyDocumentDownload = async (clientPolicyDocumentID) => {
    if (
      !state?.isInitialized ||
      !state?.clientPolicyExists ||
      !state.clientPolicy?.client_policy_id
    ) {
      throw new Error('No policy in context')
    }
    await axios
      .get(`/client/policy/document/download`, {
        params: { clientPolicyDocumentID: clientPolicyDocumentID },
        responseType: 'blob',
      })
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })
      .then((response) => {
        let filename = ''
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers['content-type'],
          })
        )
        let disposition = response.headers['content-disposition']

        if (disposition && disposition.indexOf('attachment') !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
            matches = filenameRegex.exec(disposition)

          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }

        let a = document.createElement('a')
        if (typeof a.download === 'undefined') {
          window.location.href = url
        } else {
          a.href = url
          a.download = filename
          document.body.appendChild(a)
          a.click()
          a.parentElement.removeChild(a)
        }
        return true
      })
    return true
  }

  const clientPolicyDocumentUpload = async (
    fileFolder,
    files,
    fileDescriptions
  ) => {
    if (
      !state?.isInitialized ||
      !state?.clientPolicyExists ||
      !state.clientPolicy?.client_policy_id
    ) {
      throw new Error('No policy in context')
    }
    if (Array.isArray(files) && files.length > 0) {
      if (!fileFolder) {
        throw new Error('File Folder is required when uploading files')
      }
      try {
        const formData = new FormData()
        formData.append('clientPolicyID', state.clientPolicy.client_policy_id)
        formData.append('clientPolicyDocumentFolder', fileFolder)
        for (let i in files) {
          formData.append(`FileUpload_${i}`, files[i])
          if (Array.isArray(fileDescriptions) && fileDescriptions[i]) {
            formData.append(`FileUpload_${i}_Desc`, fileDescriptions[i])
          }
        }
        // build fromdata
        await axios
          .put(`/client/policy/document/upload`, formData, {
            headers: {
              'content-type': 'multipart/form-data',
            },
          })
          .catch(async (errOrPromise) => {
            if (errOrPromise instanceof Error) {
              throw errOrPromise
            } else if (errOrPromise instanceof Promise) {
              return errOrPromise
            }
          })
      } catch (err) {
        console.log(err)
        let e = new Error(
          'Some (or all) documents failed to upload - but not was secure. Please make sure to go in and attach all valid files'
        )
        e.name = 'ConsultechFileUploadErr'
        throw e
      }
    }
    return true
  }

  const clientPolicyDocumentDelete = async (clientPolicyDocumentID) => {
    if (
      !state?.isInitialized ||
      !state?.clientPolicyExists ||
      !state.clientPolicy?.client_policy_id
    ) {
      throw new Error('No policy in context')
    }
    const res = await axios
      .delete(`/client/policy/document/delete`, {
        params: {
          clientPolicyDocumentID: clientPolicyDocumentID,
        },
      })
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })
    // 404 is fine because that means the file doesn't exist anymore.
    if (res.status !== 204 && res.status !== 404) {
      throw new Error(res.statusText)
    }
    return true
  }

  // initialize

  const initialize = async () => {
    try {
      if (isNaN(clientPolicyID) || clientPolicyID === null) {
        dispatch({
          type: 'CLIENT_POLICY_PURGE',
          payload: {
            contextClientPolicyID: clientPolicyID,
          },
        })
        return
      }
      const clientPolicyState = JSON.parse(
        localStorage.clientPolicyState ?? JSON.stringify(initialState)
      )
      if (
        clientPolicyState?.contextClientPolicyID !== null &&
        clientPolicyID === clientPolicyState?.contextClientPolicyID
      ) {
        dispatch({
          type: 'CLIENT_POLICY_INTIALIZE',
          payload: {
            ...clientPolicyState,
          },
        })
        return
      } else {
        dispatch({
          type: 'CLIENT_POLICY_INTIALIZE',
          payload: {
            ...initialState,
          },
        })
        const res = await axios
          .get('/client/policy/get', {
            params: { clientPolicyID: clientPolicyID },
          })
          .catch(async (errOrPromise) => {
            if (errOrPromise instanceof Error) {
              throw errOrPromise
            } else if (errOrPromise instanceof Promise) {
              return errOrPromise
            }
          })
        if (res.status === 200) {
          dispatch({
            type: 'CLIENT_POLICY_INTIALIZE',
            payload: {
              ...state,
              clientPolicy: res.data,
              clientPolicyLoaded: true,
              clientPolicyExists: true,
            },
          })
          return
        }
      }
    } catch (err) {
      console.log(err)
      dispatch({
        type: 'CLIENT_POLICY_PURGE',
        payload: {
          contextClientPolicyID: clientPolicyID,
        },
      })
    }
  }

  useEffect(initialize, [])
  useEffect(() => {
    updateClientPolicyContext({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientPolicyID])

  // store state in local storage whenever state changes in memory
  useEffect(() => {
    const asyncCallback = async () => {
      if (state.isInitialized) {
        dispatch({ type: 'STORE_STATE' })
      }
    }

    asyncCallback()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return (
    <ClientPolicyContext.Provider
      value={{
        ...state,
        updateClientPolicyContext,
        clientPolicyUpdate,
        clientPolicyWorklogSearch,
        clientPolicyWorklogCreate,
        clientPolicyDocumentUpload,
        clientPolicyDocumentDelete,
        clientPolicyDocumentDownload,
        clientPolicyDocumentSearch,
      }}
    >
      {children}
    </ClientPolicyContext.Provider>
  )
}

export { ClientPolicyContext, ClientPolicyProvider }
