import React, { useRef } from 'react'

import Box from '@mui/material/Box'

const ScrollableBox = ({ children, setScrolled, height = '20vh' }) => {
  const listInnerRef = useRef()

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current

      if (scrollTop + clientHeight >= scrollHeight) {
        setScrolled(true)
      }
    }
  }

  return (
    <Box>
      <Box
        sx={{ height, overflowY: 'auto' }}
        onScroll={onScroll}
        ref={listInnerRef}
      >
        {children}
      </Box>
    </Box>
  )
}

export default ScrollableBox
