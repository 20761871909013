import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useFormikContext } from 'formik'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'

import UploadFileIcon from '@mui/icons-material/UploadFile'
import CloseIcon from '@mui/icons-material/Close'

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  overflowX: 'auto',
  marginTop: 4,
}

const thumbInner = {
  minWidth: 0,
  overflow: 'hidden',
  width: 'auto',
  minHeight: '80px',
  maxHeight: '135px',
  position: 'relative',
}

const Thumbnail = ({ file, index, fieldName, descFieldName }) => {
  const { values, handleChange } = useFormikContext()
  const [filename, setFilename] = useState(file.name)
  const [fileDescription, setFileDescription] = useState('')
  const [originalFilename] = useState(file.name)

  const handleDelete = () => {
    const files = [...values[fieldName]]
    files.splice(index, 1)
    handleChange({ target: { name: fieldName, value: files } })
    if (descFieldName !== undefined) {
      const fileDescs = [...values[descFieldName]]
      fileDescs.splice(index, 1)
      handleChange({ target: { name: descFieldName, value: fileDescs } })
    }
  }

  const handleRename = (e) => {
    setFilename(e.target.value)
  }

  const handleFileDescriptionChange = (e) => {
    setFileDescription(e.target.value)
  }

  const handleFileBlur = () => {
    const files = [...values[fieldName]]
    files[index] = {
      ...files[index],
      filename: filename,
    }
    handleChange({ target: { name: fieldName, value: files } })
  }

  const handleDescBlur = () => {
    if (descFieldName !== undefined) {
      const fileDescs = [...values[descFieldName]]
      fileDescs[index] = fileDescription
      handleChange({ target: { name: descFieldName, value: fileDescs } })
    }
  }

  return (
    <Box key={`${file.name}-${index}`}>
      <Grid
        container
        spacing={4}
        sx={{
          ...thumbInner,
        }}
      >
        <Grid item xs={2} sm={1} sx={{ display: 'flex' }}>
          <IconButton onClick={handleDelete} sx={{ my: 'auto' }}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
          item
          sm={2}
          xs={10}
        >
          <img
            src={file.preview}
            style={{
              maxHeight: '75px',
              height: 'auto',
              width: 'auto',
              maxWidth: '70px',
              overflow: 'hidden',
            }}
            alt=""
          />
        </Grid>
        <Grid
          item
          xs={descFieldName !== undefined ? 6 : 12}
          sm={descFieldName !== undefined ? 6 : 9}
          sx={{ display: 'flex' }}
        >
          <TextField
            fullWidth={true}
            sx={{ my: 'auto' }}
            label={`Filename: (Original ${originalFilename})`}
            onChange={handleRename}
            onBlur={handleFileBlur}
            value={file.name || filename}
          />
        </Grid>
        {descFieldName !== undefined && (
          <Grid item xs={6} sm={3} sx={{ display: 'flex' }}>
            <TextField
              fullWidth={true}
              sx={{ my: 'auto' }}
              label={`File Description`}
              onChange={handleFileDescriptionChange}
              onBlur={handleDescBlur}
              value={fileDescription}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

const Dropzone = ({ fieldData }) => {
  const { values, handleChange } = useFormikContext()

  const files = values[fieldData.field] || []
  const fileDescriptions =
    fieldData.descField && typeof fieldData.descField === 'string'
      ? values[fieldData.descField] || []
      : undefined

  const setFiles = (newFiles) => {
    handleChange({
      target: {
        name: fieldData.field,
        value: newFiles,
      },
    })
  }

  const setFileDescriptions = (newDescriptions) => {
    if (Array.isArray(fileDescriptions)) {
      handleChange({
        target: {
          name: fieldData.descField,
          value: newDescriptions,
        },
      })
    }
  }

  const getFilePreview = (file) => {
    switch (file.type) {
      case 'image/png':
        return URL.createObjectURL(file)
      case 'application/pdf':
        return `/static/imgs/pdf.png`
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12':
      case 'application/vnd.ms-excel':
      case 'application/vnd.ms-excel.sheet.macroEnabled.12':
      case 'text/csv':
      case 'application/csv':
        return `/static/imgs/excel.png`
      case 'application/msword':
      case 'application/vnd.ms-word.document.macroEnabled.12':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
      case 'application/vnd.ms-word.template.macroEnabled.12':
        return `/static/imgs/word.png`
      default:
        return `/static/imgs/file.png`
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles(
        files.concat(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: getFilePreview(file),
              filename: file.name,
            })
          )
        )
      )
      if (Array.isArray(fileDescriptions)) {
        setFileDescriptions(
          fileDescriptions.concat(Array.from(acceptedFiles, (_) => ''))
        )
      }
    },
  })

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview))
    },
    [files]
  )

  return (
    <Container>
      <Typography>{fieldData.display}</Typography>
      <Box
        component="div"
        {...getRootProps({
          sx: {
            minHeight: '75px',
            border: '1px dashed',
            borderColor: 'primary.main',
            cursor: 'pointer',
          },
        })}
      >
        <Box component="input" {...getInputProps()} sx={{ height: '100%' }} />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            height: '100%',
            textAlign: 'center',
          }}
        >
          <Box
            sx={{
              width: '80%',
              m: 'auto',
              justifyItems: 'center',
            }}
          >
            <UploadFileIcon />
            <Typography>
              Upload files (click here or drag and drop them)
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={thumbsContainer}>
        <Stack spacing={2} sx={{ width: '100%' }}>
          {files.map((file, index) => (
            <Thumbnail
              key={index}
              file={file}
              index={index}
              fieldName={fieldData.field}
              descFieldName={
                fieldData.descField && typeof fieldData.descField === 'string'
                  ? fieldData.descField
                  : undefined
              }
            />
          ))}
        </Stack>
      </Box>
    </Container>
  )
}

export default Dropzone
