import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Formik } from 'formik'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Collapse from '@mui/material/Collapse'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FlashFeedback from 'components/common/FlashFeedback'

import FieldGroup from 'components/common/FormRenderer/FieldGroup'
import { Link } from 'react-router-dom'

import DataTable from 'components/common/DataTable'
import resolveErrorText from 'helpers/resolveErrorText'

import useClientContext from 'hooks/context/useClientContext'
import useAuth from 'hooks/context/useAuth'

import { format, parseISO } from 'date-fns'

const paginationLimit = 50

function ClientNotes() {
  const { client, clientNoteSearch, clientNoteCreate } = useClientContext()
  const { user } = useAuth()
  const formikRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [openAttachDocuments, setOpenAttachDocuments] = useState(false)
  const [notePage, setNotePage] = useState(0)
  const [noteDisplay, setNoteDisplay] = useState([])
  const [noteTotal, setNoteTotal] = useState(0)
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const [createClientNoteFormOpen, setCreateClientNoteFormOpen] =
    useState(false)

  const resetCreateClientNoteForm = () => {
    formikRef.current.resetForm()
  }

  const [tzStr] = useState(
    <>
      &nbsp;
      {
        new Date()
          .toLocaleTimeString('en-us', {
            timeZoneName: 'short',
          })
          .split(' ')[2]
      }
    </>
  )

  const initNoteLookup = useCallback(async () => {
    if (client?.client_id) {
      setLoading(true)
      try {
        let res = await clientNoteSearch(
          null,
          null,
          null,
          new Date().toISOString(),
          {
            client_note_id: 'DESC',
          },
          undefined,
          paginationLimit,
          notePage * paginationLimit
        )
        setNoteDisplay(
          (res.client_notes || []).map((note) => {
            return {
              User: note.user_name,
              Created: (
                <>
                  {format(
                    parseISO(note.client_note_created_datetime),
                    'M/d/y h:mm a'
                  )}
                  {tzStr}
                </>
              ),
              '# Files': note.files_attached,
              Note: note.client_note_description,
              '': (
                <Button
                  component={Link}
                  to={
                    '/console/clients/' +
                    client.client_id +
                    '/notes/' +
                    note.client_note_id
                  }
                  color="primary"
                  variant="contained"
                >
                  Manage
                </Button>
              ),
            }
          })
        )
        setNoteTotal(res.total)
      } catch (err) {
        setError(resolveErrorText(err))
      } finally {
        setLoading(false)
      }
    }
  }, [client, clientNoteSearch, notePage, tzStr])

  useEffect(() => {
    ;(async function () {
      await initNoteLookup()
    })()
  }, [notePage, client, initNoteLookup])

  const handleSubmit = async (values, { setErrors }) => {
    let loadNewNotes = true
    try {
      setLoading(true)
      if (!values?.client_note_description?.length) {
        throw new Error('Notes must have a length')
      }
      if (
        Array.isArray(values.files) &&
        values.files.length > 0 &&
        Array.isArray(values.fileDescs) &&
        values.fileDescs.length !== values.files.length
      ) {
        throw new Error(
          'There should be as many file descriptions as there are files'
        )
      }
      await clientNoteCreate(
        user.user_id,
        values.client_note_description,
        values.files || null,
        values.fileDescs || null
      )
      // don't care about the response - bad ones will throw errors
    } catch (err) {
      setErrors({ submit: resolveErrorText(err) })
      loadNewNotes = err.name === 'ConsultechFileUploadErr'
    }

    try {
      if (loadNewNotes) {
        if (notePage === 0) {
          setNoteTotal(noteTotal + 1)
        } else {
          setNotePage(0)
        }
        resetCreateClientNoteForm()
        setOpenAttachDocuments(false)
        initNoteLookup()
        setFeedbackOpen(true)
        setCreateClientNoteFormOpen(false)
      }
    } catch (e) {
      setError(resolveErrorText(e))
    } finally {
      setLoading(false)
    }
  }

  return (
    client.client_id && (
      <Box>
        <FlashFeedback
          open={feedbackOpen}
          severity={'success'}
          setOpen={setFeedbackOpen}
          message={'Note Added Successfully'}
        />

        <Paper
          sx={{ display: 'flex', flexDirection: 'column', padding: '.5em' }}
        >
          <Accordion
            mb={2}
            expanded={createClientNoteFormOpen}
            sx={{ border: '1px solid black' }}
          >
            <AccordionSummary
              onClick={() => {
                if (createClientNoteFormOpen) {
                  resetCreateClientNoteForm()
                }
                setCreateClientNoteFormOpen(!createClientNoteFormOpen)
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography component="h3" variant="h3">
                <strong>Add Note:</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {error && <Alert severity="warning">{error}</Alert>}
              <Formik
                initialValues={{
                  client_note_description: '',
                }}
                onSubmit={handleSubmit}
                innerRef={formikRef}
              >
                <Stack mt={2} mb={1} p={1}>
                  {formikRef?.current?.errors?.submit && (
                    <Alert severity="warning">
                      {formikRef?.current?.errors?.submit}
                    </Alert>
                  )}
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12} sm={10}>
                      <FieldGroup
                        fieldData={{
                          field: 'client_note_description',
                          display: 'Enter your note...',
                          type: 'text',
                          InputProps: {
                            multiline: true,
                            required: true,
                            disabled: loading,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        fullWidth={true}
                        sx={{
                          marginBottom: openAttachDocuments ? '3px' : '0px',
                        }}
                        variant="contained"
                        color={!openAttachDocuments ? 'primary' : 'error'}
                        onClick={() => {
                          setLoading(true)
                          if (openAttachDocuments) {
                            delete formikRef.current.values.files
                          }
                          setOpenAttachDocuments(
                            (openAttachDocuments) => !openAttachDocuments
                          )
                          setLoading(false)
                        }}
                      >
                        {!openAttachDocuments
                          ? 'Attach Documents'
                          : 'Cancel Attaching Documents'}
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Collapse in={openAttachDocuments}>
                        <FieldGroup
                          fieldData={{
                            field: 'files',
                            descField: 'fileDescs',
                            display: '',
                            type: 'fileupload',
                            InputProps: {
                              disabled: loading || !openAttachDocuments,
                            },
                          }}
                        />
                      </Collapse>
                    </Grid>
                    <Grid item xs={12} sm={10}></Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        disabled={loading}
                        fullWidth={true}
                        color="primary"
                        variant="contained"
                        onClick={() => formikRef?.current?.submitForm()}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </Formik>
            </AccordionDetails>
          </Accordion>
          {loading ? (
            <Typography component="h2" variant="h2">
              Loading
            </Typography>
          ) : client ? (
            <Box mt={4}>
              <DataTable
                tableHeadStyling={{
                  whiteSpace: 'nowrap',
                }}
                tableCellStyling={{
                  Note: {
                    whiteSpace: 'pre-wrap',
                  },
                  core: {
                    whiteSpace: 'nowrap',
                  },
                }}
                title=""
                noRowsNode={<Typography>This client has no notes</Typography>}
                paginationLimit={paginationLimit}
                tableRows={noteDisplay}
                currentPage={notePage}
                setCurrentPage={setNotePage}
                totalResults={noteTotal}
              />
            </Box>
          ) : null}
        </Paper>
      </Box>
    )
  )
}

export default ClientNotes
