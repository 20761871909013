import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Formik } from 'formik'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Collapse from '@mui/material/Collapse'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FlashFeedback from 'components/common/FlashFeedback'

import FieldGroup from 'components/common/FormRenderer/FieldGroup'
import { Link } from 'react-router-dom'

import DataTable from 'components/common/DataTable'
import resolveErrorText from 'helpers/resolveErrorText'

import useClientContext from 'hooks/context/useClientContext'

import { format, parseISO } from 'date-fns'

const paginationLimit = 50

const clientPolicyDocumentFolderOptions = [
  {
    display: 'Select a Folder',
    value: 'INVALID',
  },
  {
    display: 'Underwriting',
    value: 'Underwriting',
  },
  {
    display: 'Quote',
    value: 'Quote',
  },
  {
    display: 'Policy',
    value: 'Policy',
  },
  {
    display: 'Certificate',
    value: 'Certificate',
  },
  {
    display: 'Setup',
    value: 'Setup',
  },
  {
    display: 'Emails',
    value: 'Emails',
  },
  {
    display: 'NOC',
    value: 'NOC',
  },
  {
    display: 'Miscellaneous',
    value: 'Miscellaneous',
  },
]

function ClientPolicies() {
  const { client, clientPolicySearch, clientPolicyCreate } = useClientContext()
  const formikRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [creatingPolicy, setCreatingPolicy] = useState(false)
  const [error, setError] = useState(false)
  const [openAttachDocuments, setOpenAttachDocuments] = useState(false)
  const [policyPage, setPolicyPage] = useState(0)
  const [policyDisplay, setPolicyDisplay] = useState([])
  const [policyTotal, setPolicyTotal] = useState(0)
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const [createClientNotePolicyOpen, setCreateClientPolicyFormOpen] =
    useState(false)

  const [tzStr] = useState(
    <>
      &nbsp;
      {
        new Date()
          .toLocaleTimeString('en-us', {
            timeZoneName: 'short',
          })
          .split(' ')[2]
      }
    </>
  )
  const resetCreateClientPolicyForm = () => {
    formikRef.current.resetForm()
  }

  const initPolicyLookup = useCallback(async () => {
    if (client?.client_id) {
      setLoading(true)
      try {
        let res = await clientPolicySearch(
          null, // clientPolicyNumber
          null,
          null,
          null, //clientPolicyEffectiveDate
          null,
          null,
          null, //clientPolicyExpirationDate
          null,
          null,
          new Date().toISOString(), //clientPolicyCreatedDatetime,
          {
            client_policy_id: 'DESC',
          },
          undefined,
          paginationLimit,
          policyPage * paginationLimit
        )
        setPolicyDisplay(
          (res.client_policies || []).map((policy) => {
            return {
              'Policy Number': policy.client_policy_number,
              'Effective Date': policy.client_policy_effective_datetime
                ? format(
                    parseISO(policy.client_policy_effective_datetime),
                    'M/d/y'
                  )
                : 'N/A',
              'Expiration Date': policy.client_policy_expiration_datetime
                ? format(
                    parseISO(policy.client_policy_expiration_datetime),
                    'M/d/y'
                  )
                : 'N/A',
              Created: (
                <>
                  {format(
                    parseISO(policy.client_policy_created_datetime),
                    'M/d/y h:mm a'
                  )}
                  {tzStr}
                </>
              ),
              '# Files': policy.files_attached,
              Actions: (
                <Button
                  component={Link}
                  to={
                    '/console/clients/' +
                    client.client_id +
                    '/policies/' +
                    policy.client_policy_id
                  }
                  color="primary"
                  variant="contained"
                >
                  Manage
                </Button>
              ),
            }
          })
        )
        setPolicyTotal(res.total)
      } catch (err) {
        setError(resolveErrorText(err))
      } finally {
        setLoading(false)
      }
    }
  }, [client, clientPolicySearch, policyPage, tzStr])

  useEffect(() => {
    ;(async function () {
      await initPolicyLookup()
    })()
  }, [policyPage, client, initPolicyLookup])

  const handleSubmit = async (values, { setErrors }) => {
    if (!loading) {
      let loadNewPolicies = true
      let errorFallthrough = null
      try {
        setCreatingPolicy(true)
        if (!values?.client_policy_number?.length) {
          throw new Error('A Policy must have a unique number')
        }
        let effectiveDatetime = null
        let expirationDatetime = null
        if (values?.client_policy_effective_datetime) {
          effectiveDatetime = new Date(values.client_policy_effective_datetime)
          if (
            !effectiveDatetime ||
            effectiveDatetime.toString() === 'Invalid Date'
          ) {
            throw new Error('Effective Date is invalid - must be a valid date')
          }
          effectiveDatetime.setHours(0)
          effectiveDatetime.setMinutes(0)
          effectiveDatetime.setSeconds(0)
          effectiveDatetime.setMilliseconds(0)
        }

        if (values?.client_policy_expiration_datetime) {
          expirationDatetime = new Date(
            values.client_policy_expiration_datetime
          )
          if (
            !expirationDatetime ||
            expirationDatetime.toString() === 'Invalid Date'
          ) {
            throw new Error('Expiration Date is invalid - must be a valid date')
          }
          expirationDatetime.setHours(23)
          expirationDatetime.setMinutes(59)
          expirationDatetime.setSeconds(59)
          expirationDatetime.setMilliseconds(0)
        }
        if (expirationDatetime && effectiveDatetime) {
          if (effectiveDatetime > expirationDatetime) {
            throw new Error(
              "A Policy's effective date cannot occur AFTER expiration datetime"
            )
          }
        }
        if (Array.isArray(values.files) && values.files.length > 0) {
          if (!values.fileFolder || values.fileFolder === 'INVALID') {
            throw new Error('File Folder is required')
          }
          if (
            Array.isArray(values.fileDescs) &&
            values.fileDescs.length !== values.files.length
          ) {
            throw new Error(
              'There should be as many file descriptions as there are files'
            )
          }
        }
        await clientPolicyCreate(
          values.client_policy_number,
          effectiveDatetime?.toISOString() || null,
          expirationDatetime?.toISOString() || null,
          values.fileFolder || null,
          values.files || null,
          values.fileDescs || null
        )
        // don't care about the response - bad ones will throw errors
      } catch (err) {
        setErrors({ submit: resolveErrorText(err) })
        loadNewPolicies = err.name === 'ConsultechFileUploadErr'
        if (loadNewPolicies) {
          errorFallthrough = err
        }
      }

      try {
        if (loadNewPolicies) {
          if (policyPage === 0) {
            setPolicyTotal(policyTotal + 1)
          } else {
            setPolicyPage(0)
          }
          resetCreateClientPolicyForm()
          setCreateClientPolicyFormOpen(false)
          setOpenAttachDocuments(false)
          initPolicyLookup()
          setFeedbackOpen(true)
        }
      } catch (e) {
        setError(resolveErrorText(e))
      } finally {
        if (errorFallthrough !== null) {
          setErrors({ submit: resolveErrorText(errorFallthrough) })
        }
        setCreatingPolicy(false)
      }
    }
  }

  return (
    client.client_id && (
      <Box>
        <Paper
          sx={{ display: 'flex', flexDirection: 'column', padding: '.5em' }}
        >
          <FlashFeedback
            open={feedbackOpen}
            severity={'success'}
            setOpen={setFeedbackOpen}
            message={'New Policy Added Successfully'}
          />
          {loading ? (
            <Typography component="h2" variant="h2">
              Loading
            </Typography>
          ) : client ? (
            <>
              <Accordion
                mb={2}
                expanded={createClientNotePolicyOpen}
                sx={{ border: '1px solid black' }}
              >
                <AccordionSummary
                  onClick={() => {
                    if (createClientNotePolicyOpen) {
                      resetCreateClientPolicyForm()
                    }
                    setCreateClientPolicyFormOpen(!createClientNotePolicyOpen)
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography component="h3" variant="h3">
                    <strong>Add Policy</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {error && <Alert severity="warning">{error}</Alert>}
                  {formikRef?.current?.errors?.submit && (
                    <Alert severity="warning">
                      {formikRef?.current?.errors?.submit}
                    </Alert>
                  )}
                  <Formik
                    initialValues={{
                      client_policy_number: '',
                      client_policy_effective_datetime: '',
                      client_policy_expiration_datetime: '',
                      fileFolder: 'INVALID',
                    }}
                    onSubmit={handleSubmit}
                    innerRef={formikRef}
                  >
                    <Stack mt={2} mb={2} p={1}>
                      <Grid container spacing={1} mt={1}>
                        <Grid item container spacing={2} xs={12} sm={10}>
                          <Grid item xs={12}>
                            <FieldGroup
                              fieldData={{
                                field: 'client_policy_number',
                                display: 'Policy Number',
                                type: 'text',
                                InputProps: {
                                  required: true,
                                  disabled: loading || creatingPolicy,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FieldGroup
                              fieldData={{
                                field: 'client_policy_effective_datetime',
                                display: 'Date Policy is effective',
                                type: 'date',
                                fullWidth: true,
                                InputProps: {
                                  disabled: loading || creatingPolicy,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FieldGroup
                              fieldData={{
                                field: 'client_policy_expiration_datetime',
                                display: 'Date policy expires',
                                type: 'date',
                                fullWidth: true,
                                InputProps: {
                                  disabled: loading || creatingPolicy,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Button
                            fullWidth={true}
                            disabled={loading || creatingPolicy}
                            sx={{
                              marginBottom: openAttachDocuments ? '3px' : '0px',
                            }}
                            variant="contained"
                            color={!openAttachDocuments ? 'primary' : 'error'}
                            onClick={() => {
                              setCreatingPolicy(true)
                              if (openAttachDocuments) {
                                delete formikRef.current.values.files
                              }
                              setOpenAttachDocuments(
                                (openAttachDocuments) => !openAttachDocuments
                              )
                              setCreatingPolicy(false)
                            }}
                          >
                            {!openAttachDocuments
                              ? 'Attach Documents'
                              : 'Cancel Attaching Documents'}
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <Collapse in={openAttachDocuments}>
                            <FieldGroup
                              fieldData={{
                                field: 'fileFolder',
                                type: 'select',
                                fieldType: 'select',
                                options: clientPolicyDocumentFolderOptions,
                                InputProps: {
                                  disabled:
                                    loading ||
                                    creatingPolicy ||
                                    !openAttachDocuments,
                                },
                              }}
                            />
                          </Collapse>
                        </Grid>
                        <Grid item xs={12}>
                          <Collapse in={openAttachDocuments}>
                            <FieldGroup
                              fieldData={{
                                field: 'files',
                                descField: 'fileDescs',
                                display: '',
                                type: 'fileupload',
                                InputProps: {
                                  disabled:
                                    loading ||
                                    creatingPolicy ||
                                    !openAttachDocuments,
                                },
                              }}
                            />
                          </Collapse>
                        </Grid>
                        <Grid item xs={12} sm={10}></Grid>
                        <Grid item xs={12} sm={2}>
                          <Button
                            disabled={loading || creatingPolicy}
                            fullWidth={true}
                            color="primary"
                            variant="contained"
                            onClick={() => formikRef?.current?.submitForm()}
                          >
                            Add
                          </Button>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Formik>
                </AccordionDetails>
              </Accordion>
              <Box mt={4}>
                <DataTable
                  tableHeadStyling={{
                    whiteSpace: 'nowrap',
                  }}
                  titleBold={true}
                  title=""
                  noRowsNode={
                    <Typography>This Client has no policies</Typography>
                  }
                  paginationLimit={paginationLimit}
                  tableRows={policyDisplay}
                  currentPage={policyPage}
                  setCurrentPage={setPolicyPage}
                  totalResults={policyTotal}
                />
              </Box>
            </>
          ) : null}
        </Paper>
      </Box>
    )
  )
}

export default ClientPolicies
