import React from 'react'
import { useFormikContext } from 'formik'

import DatePicker from 'components/common/DatePicker'

function DateField({ fieldData }) {
  const { values, handleChange, errors } = useFormikContext()
  const { type, callback, field, display, options, large, ...inputProps } =
    fieldData

  const setDate = (date) => {
    handleChange({
      target: {
        name: field,
        value: date,
      },
    })
  }

  return (
    <DatePicker
      date={values[field]}
      setDate={setDate}
      handleAccept={(value) => {
        setDate(value)
        if (callback) {
          callback(value, { values, handleChange })
        }
      }}
      label={display}
      inputProps={{
        ...inputProps,
        error: Boolean(errors[field]),
        helperText: errors[field],
      }}
    />
  )
}

export default DateField
