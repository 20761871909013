import React, { Children, useState, useEffect } from 'react'

import Slide from '@mui/material/Slide'
import Box from '@mui/material/Box'

function Slideshow({ frame, children }) {
  const [currentFrame, setCurrentFrame] = useState(frame)
  const [reverse, setReverse] = useState(false)

  useEffect(() => {
    if (frame < currentFrame) {
      setReverse(true)
    } else {
      setReverse(false)
    }

    setCurrentFrame(frame)
  }, [frame, currentFrame])

  return (
    <Box sx={{ position: 'relative', height: '50vh' }}>
      {Children.map(children, (child, index) => {
        const enterDirection = reverse ? 'right' : 'left'
        const exitDirection = reverse ? 'left' : 'right'

        return (
          <Slide
            appear={index > 0}
            in={currentFrame === index}
            direction={currentFrame === index ? enterDirection : exitDirection}
            unmountOnExit
          >
            <Box
              sx={{
                position: 'absolute',
                top: '0',
              }}
            >
              {child}
            </Box>
          </Slide>
        )
      })}
    </Box>
  )
}

export default Slideshow
