import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

const ActionButton = styled(Button)`
  border-radius: ${({ footer }) => (footer ? '8px' : '24px')};
  margin-top: auto;
  margin-bottom: auto;
  white-space: nowrap;
`

function ActionButtons({ actionProps, footer }) {
  const adjustedActionProps = actionProps

  const getDefaultProps = (actionProp) => {
    const { active } = actionProp
    const props = {}
    props.size = footer ? 'action-footer' : 'action-header'

    if (!footer) {
      props.color = active ? 'primary' : 'disabled'
      props.variant = 'contained'
    }

    return props
  }

  return (
    <Grid
      container
      columns={12}
      rowSpacing={2}
      columnSpacing={8}
      sx={{
        flexDirection: footer ? 'row-reverse' : 'row',
        float: footer ? 'right' : undefined,
        height: '100%',
      }}
    >
      {adjustedActionProps.map((actionProp, index) => (
        <Grid
          item
          sx={{ display: 'flex' }}
          key={`${actionProp.children}-${index}`}
        >
          <ActionButton
            footer={footer && 'true'}
            xs={2}
            {...getDefaultProps(actionProp)}
            {...actionProp}
          />
        </Grid>
      ))}
    </Grid>
  )
}

ActionButtons.propTypes = {
  actionProps: PropTypes.arrayOf(PropTypes.object),
  footer: PropTypes.bool,
}

export default ActionButtons
