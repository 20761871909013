import axios from 'utils/axios'

const useClient = () => {
  // const clientGet = async (clientID, includeJoins) => {
  //   const { data } = await axios.get(`/client/get`, {
  //     params: {
  //       clientID: clientID,
  //       includeJoins: Boolean(includeJoins),
  //     },
  //   })
  //   return data
  // }

  const clientSearch = async (
    clientName,
    clientFEIN,
    clientIsProspect,
    clientIsActive,
    includeJoins,
    sort,
    sortOrder,
    limit,
    offset
  ) => {
    let searchTerms = {}
    if (typeof clientName === 'string' && clientName.length > 0) {
      searchTerms.client_name = clientName
    }
    if (typeof clientFEIN === 'string' && clientFEIN.length > 0) {
      searchTerms.client_fein = clientFEIN
    }
    if (typeof clientIsProspect === 'boolean') {
      searchTerms.client_is_prospect = clientIsProspect
    }
    if (typeof clientIsActive === 'boolean') {
      searchTerms.client_is_active = clientIsActive
    }
    if (typeof includeJoins === 'string' && includeJoins.length > 0) {
      searchTerms.include_joins = includeJoins
    }
    if (sort && typeof sort === 'object' && Object.keys(sort).length > 0) {
      searchTerms.sort = sort
    }
    if (Array.isArray(sortOrder) && sortOrder.length > 0) {
      searchTerms.sort_order = sortOrder
    }
    // 0 = no limit
    if (!isNaN(limit) && parseInt(limit, 10) >= 0) {
      searchTerms.limit = limit
    }
    if (!isNaN(offset) && parseInt(offset, 10) >= 0) {
      searchTerms.offset = offset
    }
    const res = await axios
      .post(`/client/search`, searchTerms)
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })

    if (!res) {
      throw new Error('API Failed to complete request')
    }
    if (res.status !== 200) {
      throw new Error(`API responded with status code ${res.status}`)
    }
    return res.data
  }

  const clientCreate = async (clientName, clientFEIN,
                              clientIsProspect, clientIsActive) => {
    const { data } = await axios
      .post(`/client/create`, {
        client_name: clientName,
        client_fein: clientFEIN || null,
        client_is_prospect: clientIsProspect || false,
        client_is_active: typeof clientIsActive === 'boolean' ?
          clientIsActive : true
      })
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })
    return data
  }

  return {
    //clientGet,
    clientSearch,
    clientCreate,
  }
}

export default useClient
