import { useContext } from 'react'
import { ClientPolicyContext } from 'contexts/ClientPolicyContext'

// wrapper for ClientContext
const useClientPolicyContext = () => {
  const context = useContext(ClientPolicyContext)

  if (!context) {
    throw new Error(
      'ClientPolicyContext must be placed within ClientPolicyProvider'
    )
  }

  return context
}

export default useClientPolicyContext
