import React from 'react'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'

function Auth({ children }) {
  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ display: 'flex', height: '100vh' }}
    >
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          minHeight: '50vh',
          my: 'auto',
          width: '100vw',
        }}
      >
        {children}
      </Box>
    </Container>
  )
}

export default Auth
