import { THEMES, COLORSHADES } from 'constants/colors'

const DEFAULT = {
  name: THEMES.DEFAULT,
  palette: {
    mode: 'light',
    primary: {
      main: COLORSHADES.BLUE[0],
      contrastText: COLORSHADES.WHITE[0],
    },
    secondary: {
      main: COLORSHADES.PURPLE[0],
      contrastText: COLORSHADES.DARKGRAY[0],
    },
    disabled: {
      main: COLORSHADES.DARKGRAY[0],
      contrastText: COLORSHADES.WHITE[0],
    },
    background: {
      main: COLORSHADES.LIGHTGRAY[1],
      default: COLORSHADES.LIGHTGRAY[1],
      paper: COLORSHADES.WHITE[0],
    },
    text: {
      primary: COLORSHADES.DARKGRAY[0],
      secondary: COLORSHADES.LIGHTGRAY[0],
      main: COLORSHADES.DARKGRAY[1],
      disabled: COLORSHADES.DARKGRAY[1],
      error: COLORSHADES.RED[0],
    },
    error: {
      main: COLORSHADES.RED[0],
    },
    warning: {
      main: COLORSHADES.YELLOW[0],
    },
    info: {
      main: COLORSHADES.GREEN[0],
    },
    success: {
      main: COLORSHADES.GREEN[0],
    },
    hover: {
      main: COLORSHADES.LIGHTGRAY[1],
    },
  },
}

const variants = [DEFAULT]

export default variants
