import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Formik } from 'formik'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'

import FieldGroup from 'components/common/FormRenderer/FieldGroup'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import resolveErrorText from 'helpers/resolveErrorText'

import useClientPolicyWorklog from 'hooks/useClientPolicyWorklog'
import useUser from 'hooks/useUser'
import useClientContext from 'hooks/context/useClientContext'
import FlashFeedback from 'components/common/FlashFeedback'
import useClientPolicyContext from 'hooks/context/useClientPolicyContext'

import { format, parseISO } from 'date-fns'

function ClientPolicyWorklog() {
  const { client } = useClientContext()
  const { clientPolicy } = useClientPolicyContext()
  const { clientPolicyWorklogGet, clientPolicyWorklogUpdate } =
    useClientPolicyWorklog()
  const { userGet } = useUser()
  const formikUpdatePolicyWorklogRef = useRef(null)
  const { clientPolicyWorklogId } = useParams()

  const [loading, setLoading] = useState(false)
  const [updatingData, setUpdatingData] = useState(false)
  const [error, setError] = useState(false)
  const [user, setUser] = useState(null)
  const [policyWorklog, setPolicyWorklog] = useState(null)
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const [tzStr] = useState(
    <>
      &nbsp;
      {
        new Date()
          .toLocaleTimeString('en-us', {
            timeZoneName: 'short',
          })
          .split(' ')[2]
      }
    </>
  )

  useEffect(() => {
    ;(async function () {
      if (clientPolicyWorklogId) {
        try {
          setLoading(true)
          setError(false)
          let loadedPolicyWorklog = await clientPolicyWorklogGet(
            clientPolicyWorklogId
          )
          if (loadedPolicyWorklog) {
            setPolicyWorklog(loadedPolicyWorklog)
            let loadedUser = await userGet(loadedPolicyWorklog.user_id)
            if (loadedUser) {
              setUser(loadedUser)
            }
          }
        } catch (e) {
          setError(resolveErrorText(e) + ' -- please reload the page')
        } finally {
          setLoading(false)
        }
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUpdatePolicyWorklog = async (values, { setErrors }) => {
    try {
      setUpdatingData(true)
      if (
        !values.client_policy_worklog_description ||
        typeof values.client_policy_worklog_description !== 'string' ||
        values.client_policy_worklog_description.length === 0
      ) {
        throw new Error('Description must be provided and have a length')
      }
      let updatedPolicyWorklog = await clientPolicyWorklogUpdate(
        policyWorklog.client_policy_worklog_id,
        values.client_policy_worklog_description
      )
      setPolicyWorklog(updatedPolicyWorklog)
      setFeedbackOpen(true)
      setTimeout(() => {
        window.location = `/console/clients/${client.client_id}/policies/${clientPolicy.client_policy_id}`
      }, 2000)
    } catch (err) {
      setErrors({ submit: resolveErrorText(err) })
      setUpdatingData(false)
    }
  }

  return (
    <Box>
      <FlashFeedback
        open={feedbackOpen}
        severity={'success'}
        setOpen={setFeedbackOpen}
        message={'Work Log Edited Successfully'}
      />
      <Paper
        sx={{ display: 'flex', flexDirection: 'column', padding: '0.5rem' }}
      >
        {error && <Alert severity="warning">{error}</Alert>}
        <Typography component="h3" variant="h3" mb={4}>
          <strong>
            {client.client_name} Policy #{clientPolicy.client_policy_number}{' '}
            Worklog #{clientPolicyWorklogId}
          </strong>
        </Typography>
        {loading ? (
          <Typography component="h2" variant="h2">
            Loading
          </Typography>
        ) : policyWorklog ? (
          <>
            <Table
              sx={{ minWidth: 700, mb: 4, p: 1 }}
              aria-label="customized table"
            >
              <TableBody>
                <TableRow>
                  <TableCell>Worklog:</TableCell>
                  <TableCell style={{ whiteSpace: 'pre-wrap' }}>
                    {policyWorklog.client_policy_worklog_description}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>User:</TableCell>
                  <TableCell>{`${user.user_first_name} ${user.user_last_name} <${user.user_email}>`}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Last Modified:</TableCell>
                  <TableCell>
                    {policyWorklog.client_policy_worklog_modified_datetime ? (
                      <>
                        {format(
                          parseISO(
                            policyWorklog.client_policy_worklog_modified_datetime
                          ),
                          'M/d/y h:mm a'
                        )}
                        {tzStr}
                      </>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Created:</TableCell>
                  <TableCell>
                    {policyWorklog.client_policy_worklog_created_datetime ? (
                      <>
                        {format(
                          parseISO(
                            policyWorklog.client_policy_worklog_created_datetime
                          ),
                          'M/d/y h:mm a'
                        )}
                        {tzStr}
                      </>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <hr />
            <Typography component="h2" variant="h1" mb={2}>
              Modify Worklog:
            </Typography>
            <Formik
              initialValues={{
                client_policy_worklog_description:
                  policyWorklog.client_policy_worklog_description,
              }}
              onSubmit={handleUpdatePolicyWorklog}
              innerRef={formikUpdatePolicyWorklogRef}
              validateOnChange={false}
              p={2}
            >
              <Stack p={1}>
                {formikUpdatePolicyWorklogRef?.current?.errors?.submit && (
                  <Alert severity="warning">
                    {formikUpdatePolicyWorklogRef?.current?.errors?.submit}
                  </Alert>
                )}
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12} sm={10}>
                    <FieldGroup
                      fieldData={{
                        field: 'client_policy_worklog_description',
                        display: 'Worklog...',
                        type: 'text',
                        InputProps: {
                          multiline: true,
                          disabled: loading || updatingData,
                          required: true,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      disabled={loading || updatingData}
                      fullWidth={true}
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        formikUpdatePolicyWorklogRef?.current?.submitForm()
                      }
                    >
                      Update Worklog
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </Formik>
          </>
        ) : null}
      </Paper>
    </Box>
  )
}

export default ClientPolicyWorklog
