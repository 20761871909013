import React from 'react'
import { useFormikContext } from 'formik'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import Field from './Field'

function FieldGroup({ fieldData, parentData }) {
  const { values } = useFormikContext()
  const { field, children } = fieldData
  const { fields, show, Transition } = children ?? {}

  const showChildren = show && show(values[field])

  return (
    <>
      <Field fieldData={fieldData} parentData={parentData ?? fieldData} />
      {fields && (
        <Transition in={showChildren} unmountOnExit>
          <Box>
            <Stack spacing={4}>
              {fields.map((childData, childIndex) => (
                <FieldGroup
                  key={`${field}-${childData.field}-${childIndex}`}
                  fieldData={childData}
                  parentData={fieldData}
                />
              ))}
            </Stack>
          </Box>
        </Transition>
      )}
    </>
  )
}

export default FieldGroup
