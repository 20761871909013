import React from 'react'

import TitleSection from 'components/home/TitleSection'
import Content from 'components/home/Content'

function Home() {
  return (
    <>
      <TitleSection />
      <Content />
    </>
  )
}

export default Home
