import React from 'react'
import { Navigate } from 'react-router-dom'

import useAuth from 'hooks/context/useAuth'

function ConsoleGuestGuard({ children }) {
  const { isAuthenticated } = useAuth()

  if (isAuthenticated) return <Navigate to="/console" replace />

  return <>{children}</>
}

export default ConsoleGuestGuard
