import React, { useState, useEffect } from 'react'

import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

const pagesStartDisplayed = 3
const pagesEndDisplayed = 3
const pagesSurroundDisplayed = 2
function DataTable({
  title,
  titleBold,
  tableDescriptionNode,
  noRowsNode,
  paginationLimit,
  tableRows,
  currentPage,
  setCurrentPage,
  totalResults,
  tableHeadStyling,
  tableCellStyling,
}) {
  const [cols, setCols] = useState([])
  const [paginationBar, setPaginationBar] = useState(null)

  useEffect(() => {
    if (tableRows?.length > 0) {
      setCols(Object.keys(tableRows[0]))
    }
  }, [tableRows])

  useEffect(() => {
    if (totalResults === 0) {
      if (currentPage !== 0) {
        setCurrentPage(0)
        return
      }
      setPaginationBar(null)
    }
    let totalPages =
      paginationLimit > 0 ? Math.ceil(totalResults / paginationLimit) : 1
    if (currentPage > totalPages) {
      setCurrentPage(totalPages)
      return
    }
    if (totalPages === 1) {
      setPaginationBar(null)
      return
    }

    let paginationSettings = Array.from(
      { length: Math.min(totalPages, pagesStartDisplayed) },
      (_, i) => i + 1
    )
    if (totalPages > pagesStartDisplayed) {
      // offset by 1
      if (currentPage - pagesSurroundDisplayed > pagesStartDisplayed + 1) {
        paginationSettings.push(null)
      }
      for (
        let i = Math.max(
          pagesStartDisplayed,
          currentPage - pagesSurroundDisplayed
        );
        i < Math.min(totalPages, currentPage + pagesSurroundDisplayed);
        i++
      ) {
        paginationSettings.push(i + 1)
      }
      if (
        totalPages - pagesEndDisplayed - 1 >
        currentPage + pagesSurroundDisplayed
      ) {
        paginationSettings.push(null)
      }
      for (
        let i = Math.max(
          currentPage + pagesSurroundDisplayed,
          totalPages - pagesEndDisplayed,
          pagesStartDisplayed
        );
        i < totalPages;
        i++
      ) {
        paginationSettings.push(i + 1)
      }
    }
    setPaginationBar(
      <Stack direction={{ xs: 'row' }}>
        {paginationSettings.map((page) => {
          if (page === null) {
            return (
              <Button
                sx={{
                  width: '30px',
                  margin: '5px',
                }}
                disabled={true}
                key={page}
              >
                ...
              </Button>
            )
          }
          return (
            <Button
              key={page}
              sx={{
                width: '30px',
                margin: '5px',
              }}
              onClick={() => {
                setCurrentPage(page - 1)
              }}
              color={page - 1 === currentPage ? 'secondary' : 'primary'}
              variant={page - 1 === currentPage ? 'contained' : 'outlined'}
            >
              {page}
            </Button>
          )
        })}
      </Stack>
    )
  }, [totalResults, currentPage, paginationLimit, setCurrentPage])

  return (
    <>
      {title && (
        <Typography
          component="h3"
          variant="h3"
          sx={{ fontWeight: titleBold ? 'bold' : 'normal' }}
        >
          {title}
        </Typography>
      )}
      {tableRows?.length ? (
        <>
          {tableDescriptionNode}
          {paginationBar}
          <Table size="small">
            <TableHead>
              <TableRow>
                {cols.map((col) => (
                  <TableCell
                    key={col}
                    sx={
                      tableHeadStyling !== null &&
                      typeof tableHeadStyling === 'object'
                        ? !(
                            tableHeadStyling[col] ||
                            tableHeadStyling.core ||
                            false
                          )
                          ? tableHeadStyling || {}
                          : {
                              ...(tableHeadStyling.core || {}),
                              ...(tableHeadStyling[col] || {}),
                            }
                        : {}
                    }
                  >
                    {col}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRows.map((row, index) => (
                <TableRow key={`row-${index}`}>
                  {cols.map((col) => (
                    <TableCell
                      key={`${col}-${index}`}
                      sx={
                        tableCellStyling !== null &&
                        typeof tableCellStyling === 'object'
                          ? !(
                              tableCellStyling[col] ||
                              tableCellStyling.core ||
                              false
                            )
                            ? tableCellStyling || {}
                            : {
                                ...(tableCellStyling.core || {}),
                                ...(tableCellStyling[col] || {}),
                              }
                          : {}
                      }
                    >
                      {row[col]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {paginationBar}
        </>
      ) : (
        noRowsNode
      )}
    </>
  )
}

export default DataTable
