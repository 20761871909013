import axios from 'axios'

const axiosInstance = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_BASE_API_URL,
    method: 'get',
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/json',
    },
  }

  // Create instance
  let instance = axios.create(defaultOptions)

  return instance
}

export default axiosInstance()
