import React, { useState, useRef } from 'react'
import { Formik } from 'formik'

import Box from '@mui/system/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import FlashFeedback from 'components/common/FlashFeedback'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'

import FieldGroup from 'components/common/FormRenderer/FieldGroup'

import resolveErrorText from 'helpers/resolveErrorText'

//import useClientContext from 'hooks/context/useClientContext'
import useClientPolicyContext from 'hooks/context/useClientPolicyContext'

function ClientPolicyEdit() {
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const { clientPolicy, clientPolicyUpdate } = useClientPolicyContext()
  const formikUpdatePolicyRef = useRef(null)
  const [updatingData, setUpdatingData] = useState(false)

  const handleUpdatePolicy = async (values, { setErrors }) => {
    try {
      setUpdatingData(true)
      if (
        !values.client_policy_number ||
        typeof values.client_policy_number !== 'string' ||
        values.client_policy_number.length === 0
      ) {
        throw new Error('Number must be provided and have a length')
      }
      let effectiveDatetime = null
      let expirationDatetime = null
      if (values?.client_policy_effective_datetime) {
        effectiveDatetime = new Date(values.client_policy_effective_datetime)
        if (
          !effectiveDatetime ||
          effectiveDatetime.toString() === 'Invalid Date'
        ) {
          throw new Error('Effective Date is invalid - must be a valid date')
        }
        effectiveDatetime.setHours(0)
        effectiveDatetime.setMinutes(0)
        effectiveDatetime.setSeconds(0)
        effectiveDatetime.setMilliseconds(0)
      }

      if (values?.client_policy_expiration_datetime) {
        expirationDatetime = new Date(values.client_policy_expiration_datetime)
        if (
          !expirationDatetime ||
          expirationDatetime.toString() === 'Invalid Date'
        ) {
          throw new Error('Expiration Date is invalid - must be a valid date')
        }
        expirationDatetime.setHours(23)
        expirationDatetime.setMinutes(59)
        expirationDatetime.setSeconds(59)
        expirationDatetime.setMilliseconds(0)
      }
      if (expirationDatetime && effectiveDatetime) {
        if (effectiveDatetime > expirationDatetime) {
          throw new Error(
            "A Policy's effective date cannot occur AFTER expiration datetime"
          )
        }
      }
      let updatePayload = {
        client_policy_number: values.client_policy_number,
        client_policy_effective_datetime:
          effectiveDatetime?.toISOString() || null,
        client_policy_expiration_datetime:
          expirationDatetime?.toISOString() || null,
      }

      await clientPolicyUpdate(updatePayload)
      setFeedbackOpen(true)
      setTimeout(() => {
        window.location = `/console/clients/${clientPolicy.client_id}/policies/${clientPolicy.client_policy_id}`
      }, 2000)
    } catch (err) {
      setErrors({ submit: resolveErrorText(err) })
      setUpdatingData(false)
    }
  }

  return (
    <Box>
      <FlashFeedback
        open={feedbackOpen}
        severity={'success'}
        setOpen={setFeedbackOpen}
        message={'Policy Edited Successfully'}
      />
      <Paper
        sx={{ display: 'flex', flexDirection: 'column', padding: '0.5rem' }}
      >
        {clientPolicy ? (
          <>
            <Typography component="h3" variant="h3" mb={2}>
              <strong>Modify Policy</strong>
            </Typography>
            <Formik
              initialValues={{
                client_policy_number: clientPolicy.client_policy_number,
                client_policy_effective_datetime:
                  clientPolicy.client_policy_effective_datetime
                    ? new Date(clientPolicy.client_policy_effective_datetime)
                    : '',
                client_policy_expiration_datetime:
                  clientPolicy.client_policy_expiration_datetime
                    ? new Date(clientPolicy.client_policy_expiration_datetime)
                    : '',
              }}
              onSubmit={handleUpdatePolicy}
              innerRef={formikUpdatePolicyRef}
              validateOnChange={false}
              p={2}
            >
              <Stack p={1}>
                {formikUpdatePolicyRef?.current?.errors?.submit && (
                  <Alert severity="warning">
                    {formikUpdatePolicyRef?.current?.errors?.submit}
                  </Alert>
                )}
                <Grid container spacing={1} mt={1}>
                  <Grid item container spacing={2} xs={12} sm={10}>
                    <Grid item xs={12}>
                      <FieldGroup
                        fieldData={{
                          field: 'client_policy_number',
                          display: 'Policy Number',
                          type: 'text',
                          InputProps: {
                            required: true,
                            disabled: updatingData,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FieldGroup
                        fieldData={{
                          field: 'client_policy_effective_datetime',
                          display: 'Date Policy is effective',
                          type: 'date',
                          fullWidth: true,
                          InputProps: {
                            disabled: updatingData,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FieldGroup
                        fieldData={{
                          field: 'client_policy_expiration_datetime',
                          display: 'Date policy expires',
                          type: 'date',
                          fullWidth: true,
                          InputProps: {
                            disabled: updatingData,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      disabled={updatingData}
                      fullWidth={true}
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        formikUpdatePolicyRef?.current?.submitForm()
                      }
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </Formik>
          </>
        ) : null}
      </Paper>
    </Box>
  )
}

export default ClientPolicyEdit
