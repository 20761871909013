import React, { useEffect, useRef, useState } from 'react'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import FieldGroup from 'components/common/FormRenderer/FieldGroup'
import { format, parseISO } from 'date-fns'

import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import useClient from 'hooks/useClient'
import resolveErrorText from 'helpers/resolveErrorText'
import FlashFeedback from 'components/common/FlashFeedback'

function Dashboard(props) {
  const createClientFormRef = useRef(null)
  const { clientCreate, clientSearch } = useClient()
  const [createClientFormOpen, setCreateClientFormOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [clientNameIndexFilter, setClientNameIndexFilter] = useState('')
  const [hasClients, setHasClients] = useState(false)
  const [clientDisplay, setClientDisplay] = useState({})
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const [feedbackText, setFeedbackText] = useState('temp')

  // by default (main dashboard), show current active clients
  const [statusFilter, setStatusFilter] = useState(
    props.filter ? props.filter : {prospect:false, active:true}
  )

  const [tzStr] = useState(
    <>
      &nbsp;
      {
        new Date()
          .toLocaleTimeString('en-us', {
            timeZoneName: 'short',
          })
          .split(' ')[2]
      }
    </>
  )

  const resolveClientNamePrefixLetter = (clientName) => {
    let usedLetter = clientName.trim()[0].toUpperCase()
    if (usedLetter.charCodeAt(0) < 65 || usedLetter.charCodeAt(0) > 91) {
      usedLetter = '%#123'
    }
    return usedLetter
  }

  const handleTabChange = (event, newTabValue) => {
    // clear out filter automatically
    setClientNameIndexFilter('')
    setStatusFilter({
      prospect: newTabValue === 'prospect',
      active: statusFilter.active
    })
  }

  const resetCreateClientForm = () => {
    createClientFormRef.current.resetForm()
  }

  const handleClientCreateSubmit = async (values, { setErrors }) => {
    setLoading(true)
    try {
      setErrors({
        submit: '',
      })
      if (!values.clientName) {
        throw new Error('Client Name is required')
      }
      let fein = (values.clientFEIN?.trim() || '').replaceAll('-', '')
      if (fein && (fein.length !== 9 || isNaN(fein))) {
        throw new Error('FEIN must be 9 digits long or must not be provided')
      }
      await clientCreate(
        values.clientName,
        fein,
        Boolean(values.clientIsProspect),
        Boolean(values.clientIsActive)
      )
      resetCreateClientForm()
      setCreateClientFormOpen(false)
      setStatusFilter({
        prospect: values.clientIsProspect,
        active: statusFilter.active
      })
      if (clientNameIndexFilter !== '') {
        let currentLetter = resolveClientNamePrefixLetter(values.clientName)
        if (clientNameIndexFilter !== currentLetter) {
          setClientNameIndexFilter(currentLetter)
        }
      }
      setFeedbackText(
        `${!!values.clientIsProspect ? 'Prospective ' : ''}Client ${
          values.clientName
        } has been created.`
      )
      setFeedbackOpen(true)
    } catch (err) {
      console.log(err)
      setErrors({
        submit: resolveErrorText(err),
      })
    } finally {
      setLoading(false)
    }
    return true
  }

  useEffect(
    () => {
      clientSearchAndFilter(statusFilter.prospect, statusFilter.active)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [statusFilter]
  )

  const clientSearchAndFilter = async (clientIsProspect, clientIsActive,
                                       setErrors) => {
    try {
      setLoading(true)
      let res = await clientSearch(
        '',
        '',
        clientIsProspect,
        clientIsActive,
        true,
        {
          client_name: 'ASC',
          client_fein: 'ASC',
          client_id: 'ASC',
        },
        ['client_name', 'client_fein', 'client_id'],
        0,
        0
      )
      if ((res.clients?.length || 0) === 0) {
        setHasClients(false)
        setClientDisplay([])
        return
      }

      let newState = {}
      // assume sort is set hard coded form above -- client ID into FEIN
      let currentLetter = ''
      for (let i in res.clients) {
        let row = {
          clientID: res.clients[i].client_id,
          clientName: res.clients[i].client_name,
          clientFEIN: res.clients[i].client_fein || null,
          clientLastModified: format(
            parseISO(res.clients[i].client_modified_datetime),
            'M/d/y h:mm a'
          ),
        }
        let usedLetter = resolveClientNamePrefixLetter(row.clientName)
        if (usedLetter !== currentLetter) {
          currentLetter = usedLetter
        }
        if (!Array.isArray(newState[currentLetter])) {
          newState[currentLetter] = []
        }
        newState[currentLetter].push(row)
      }
      setHasClients(true)
      setClientDisplay(newState)
    } catch (e) {
      console.log(e)
      if (setErrors) {
        setErrors(resolveErrorText(e))
      }
      return false
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <FlashFeedback
        open={feedbackOpen}
        severity={'success'}
        setOpen={setFeedbackOpen}
        message={feedbackText}
      />
      <Accordion
        expanded={createClientFormOpen}
        sx={{
          border: '1px solid black',
          display: props.filter ? 'none' : ''
        }}
      >
        <AccordionSummary
          onClick={() => {
            if (createClientFormOpen) {
              resetCreateClientForm()
            }
            setCreateClientFormOpen(!createClientFormOpen)
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <strong>Create New Client</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {createClientFormRef?.current?.errors?.submit && (
            <Alert severity="warning">
              {createClientFormRef?.current?.errors?.submit}
            </Alert>
          )}
          <Formik
            initialValues={{
              clientName: null,
              clientFEIN: null,
              clientIsProspect: true,
              clientIsActive: true,
            }}
            onSubmit={handleClientCreateSubmit}
            innerRef={createClientFormRef}
          >
            <Stack mt={2} mb={2} p={1}>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} sm={4}>
                  <FieldGroup
                    fieldData={{
                      field: 'clientName',
                      display: 'Client Name',
                      type: 'text',
                      fullWidth: true,
                      InputProps: {
                        required: true,
                        disabled: loading,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FieldGroup
                    fieldData={{
                      field: 'clientFEIN',
                      display: 'Client FEIN',
                      type: 'text',
                      fullWidth: true,
                      InputProps: {
                        required: true,
                        disabled: loading,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <FieldGroup
                    fieldData={{
                      field: 'clientIsProspect',
                      display: 'Is Prospect',
                      type: 'select',
                      fieldType: 'select',
                      options: [
                        {
                          value: true,
                          display: 'Yes',
                        },
                        {
                          value: false,
                          display: 'No',
                        },
                      ],
                      fullWidth: true,
                      InputProps: {
                        required: true,
                        disabled: loading,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <FieldGroup
                    fieldData={{
                      field: 'clientIsActive',
                      display: 'Is Active',
                      type: 'select',
                      fieldType: 'select',
                      options: [
                        {
                          value: true,
                          display: 'Yes',
                        },
                        {
                          value: false,
                          display: 'No',
                        },
                      ],
                      fullWidth: true,
                      InputProps: {
                        required: true,
                        disabled: loading,
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1} mb={4}>
                <Grid item xs={12} sm={6}>
                  <Button
                    disabled={loading}
                    fullWidth={true}
                    color="primary"
                    variant="contained"
                    onClick={() => createClientFormRef?.current?.submitForm()}
                  >
                    Create Client
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </Formik>
        </AccordionDetails>
      </Accordion>
      <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
        <TabContext value={statusFilter.prospect ? "prospect" : "current"}>
          <Box sx={props.filter ?
            {paddingBottom: '4px', height: 0, visibility: 'hidden'} :
            {borderBottom: 1, borderColor: 'divider'}
          }>
            <TabList onChange={handleTabChange} aria-label="case tabs">
              <Tab label="Prospective Clients" value="prospect" />
              <Tab label="Current Clients" value="current" />
            </TabList>
          </Box>
          {loading ? (
            <Typography>Loading...</Typography>
          ) : !hasClients ? (
            <Typography variant="h3" component="h3" p={2}>
              No {statusFilter.prospect ? 'prospective ' : 'current '}
              {statusFilter.active ? 'active ' : 'inactive '}clients have
              been found.
            </Typography>
          ) : (
            <>
              <Typography
                p={2}
                component="h2"
                variant="h2"
                mb={2}
                textAlign="left"
              >
                Filter By Name
              </Typography>
              <Grid container spacing={0.5} p={5}>
                <Grid item sm={1} xs={2}>
                  <Button
                    onClick={() => {
                      setClientNameIndexFilter('')
                    }}
                  >
                    Clear
                  </Button>
                </Grid>
                {Object.keys(clientDisplay).map((key) => (
                  <Grid key={key} item sm={1} xs={2}>
                    <Button
                      fullWidth={true}
                      color={
                        clientNameIndexFilter === key ? 'secondary' : 'primary'
                      }
                      variant={
                        clientNameIndexFilter === key ? 'contained' : 'outlined'
                      }
                      disabled={clientNameIndexFilter === key}
                      onClick={() => {
                        setClientNameIndexFilter(key)
                      }}
                    >
                      {key}
                    </Button>
                  </Grid>
                ))}
              </Grid>

              <TabPanel value="prospect" index={0}>
                <Grid container spacing={2} p={2}>
                  <Grid item xs={12}>
                    <Typography
                      component="h2"
                      variant="h2"
                      mb={2}
                      textAlign="center"
                    >
                      <strong>Prospective Clients</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer>
                      <Table>
                        {Object.keys(clientDisplay).map((key) => (
                          <React.Fragment key={`${key}`}>
                            {!clientNameIndexFilter ||
                            key === clientNameIndexFilter ? (
                              <>
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      colSpan={4}
                                      sx={{ backgroundColor: '#d9edf7' }}
                                    >
                                      {key}:
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>FEIN</TableCell>
                                    <TableCell>Last Modified</TableCell>
                                    <TableCell></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {clientDisplay[key].map((client) => (
                                    <TableRow key={client.clientID}>
                                      <TableCell>{client.clientName}</TableCell>
                                      <TableCell>
                                        {!client.clientFEIN
                                          ? 'N/A'
                                          : `${client.clientFEIN.substring(
                                              0,
                                              2
                                            )}-${client.clientFEIN.substring(
                                              2,
                                              9
                                            )}`}
                                      </TableCell>
                                      <TableCell>
                                        {client.clientLastModified}
                                        {tzStr}
                                      </TableCell>
                                      <TableCell>
                                        <Link
                                          to={`/console/clients/${client.clientID}`}
                                        >
                                          <Button
                                            variant="contained"
                                            fullWidth={true}
                                          >
                                            Manage
                                          </Button>
                                        </Link>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </>
                            ) : null}
                          </React.Fragment>
                        ))}
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="current" index={1}>
                <Grid container spacing={2} p={2}>
                  <Grid item xs={12}>
                    <Typography
                      component="h2"
                      variant="h2"
                      mb={2}
                      textAlign="center"
                    >
                      <strong>Current Clients</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer>
                      <Table>
                        {Object.keys(clientDisplay).map((key) => (
                          <React.Fragment key={`${key}`}>
                            {!clientNameIndexFilter ||
                            key === clientNameIndexFilter ? (
                              <>
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      colSpan={4}
                                      sx={{
                                        backgroundColor: '#d9edf7',
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                      }}
                                    >
                                      {key}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>FEIN</TableCell>
                                    <TableCell>Last Modified</TableCell>
                                    <TableCell></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {clientDisplay[key].map((client) => (
                                    <TableRow key={client.clientID}>
                                      <TableCell>{client.clientName}</TableCell>
                                      <TableCell>
                                        {!client.clientFEIN
                                          ? 'N/A'
                                          : `${client.clientFEIN.substring(
                                              0,
                                              2
                                            )}-${client.clientFEIN.substring(
                                              2,
                                              9
                                            )}`}
                                      </TableCell>
                                      <TableCell>
                                        {client.clientLastModified}
                                        {tzStr}
                                      </TableCell>
                                      <TableCell>
                                        <Link
                                          to={`/console/clients/${client.clientID}`}
                                        >
                                          <Button
                                            variant="contained"
                                            fullWidth={true}
                                          >
                                            Manage
                                          </Button>
                                        </Link>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </>
                            ) : null}
                          </React.Fragment>
                        ))}
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </TabPanel>
            </>
          )}
        </TabContext>
      </Paper>
    </>
  )
}

export default Dashboard
