const isValidToken = ({ accessToken, accessTokenExpires }) => {
  if (!accessToken) {
    return false
  }
  const expireTime = accessTokenExpires * 1000
  const currentTime = Date.now()

  return currentTime < expireTime
}

export default isValidToken
