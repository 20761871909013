import React, { useRef, useState } from 'react'
import * as Yup from 'yup'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'

import FormRenderer from 'components/common/FormRenderer'

import useAuth from 'hooks/context/useAuth'
import resolveErrorText from 'helpers/resolveErrorText'

function UserLogin() {
  const formikRef = useRef(null)
  const { login } = useAuth()

  const [loading, setLoading] = useState(false)

  const handleSubmit = async (values, { setStatus }) => {
    setLoading(true)
    login(values).catch((err) => {
      setStatus({
        errors: {
          submit: resolveErrorText(err),
        },
      })
      setLoading(false)
    })
  }

  const renderingData = [
    {
      field: 'email',
      display: 'Email address',
      type: 'email',
    },
    {
      field: 'password',
      display: 'Password',
      type: 'password',
    },
  ]

  const footerActionProps = [
    {
      children: 'Submit',
      color: 'primary',
      variant: 'contained',
      onClick: () => formikRef?.current?.submitForm(),
      disabled: loading,
    },
  ]

  const error = formikRef?.current?.errors?.submit

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required'),
    password: Yup.string().required('Password is required'),
  })

  return (
    <Box sx={{ width: '100%' }}>
      {error && (
        <Alert severity="warning">{formikRef?.current?.errors?.submit}</Alert>
      )}
      <Typography component="h1" variant="h1" mb={4}>
        Sign In
      </Typography>
      <FormRenderer
        renderingData={renderingData}
        footerActionProps={footerActionProps}
        innerRef={formikRef}
        handleSubmit={handleSubmit}
        validationSchema={validationSchema}
      />
    </Box>
  )
}

export default UserLogin
