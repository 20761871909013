export const THEMES = {
  DEFAULT: 'DEFAULT',
}

export const COLORSHADES = {
  DARKGRAY: ['#3F4144', '#1E1926'],
  LIGHTGRAY: ['#7D7D82', '#F5F5F5'],
  WHITE: ['#FFFFFF'],
  BLUE: ['#1414FF'],
  RED: ['#9E1E00'],
  GREEN: ['#006A5F'],
  PURPLE: ['#B6A2D7'],
  YELLOW: ['#FFBC36'],
}
