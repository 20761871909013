import React, { useState } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import MFACode from 'components/userlogin/MFACode'
import Slideshow from 'components/common/Slideshow'

import useAuth from 'hooks/context/useAuth'

function MFA() {
  const { payload: qrCode } = useAuth()
  const [frame, setFrame] = useState(0)

  const handleClick = () => {
    setFrame(frame ^ 1)
  }

  return (
    <Box
      sx={{
        minWidth: '100% !important',
        maxWidth: '30vw',
        width: '300px',
      }}
    >
      <Slideshow frame={frame}>
        <>
          <Typography component="h1" variant="h1" mb={4}>
            QR Code
          </Typography>
          <Box>
            <img src={`data:image/png;base64,${qrCode}`} alt="QR Code" />
          </Box>
          <Box component="p" sx={{ textAlign: 'left' }}>
            <Typography component="small" variant="muted">
              <strong>
                Please do not scan this code with your phone's camera app -- it
                won't work!
              </strong>
            </Typography>
            <br />
            <br />
            <Typography component="small" variant="muted">
              Our 2-factor authentication system requires you to use a 3rd party
              app on your mobile phone. Please install one of the following:
            </Typography>
            <br />
            <br />
            <Typography component="small" variant="muted">
              * Google Authenticator
              <a
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                target="_blank"
                rel="noopener noreferrer"
              >
                [Android]
              </a>
              <a
                href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                target="_blank"
                rel="noopener noreferrer"
              >
                [iOS]
              </a>
              <br />* Microsoft Authenticator
              <a
                href="https://play.google.com/store/apps/details?id=com.azure.authenticator"
                target="_blank"
                rel="noopener noreferrer"
              >
                [Android]
              </a>
              <a
                href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
                target="_blank"
                rel="noopener noreferrer"
              >
                [iOS]
              </a>
              <br />* Any other TOTP compatible authenticator app
            </Typography>
          </Box>
          <Button color="primary" variant="contained" onClick={handleClick}>
            Continue
          </Button>
        </>
        <Box>
          <MFACode setFrame={setFrame} frame={frame} />
        </Box>
      </Slideshow>
    </Box>
  )
}

export default MFA
