import React, { useReducer, createContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import axios from 'utils/axios'

const initialState = {
  isInitialized: false,
  clientLoaded: false,
  clientExists: false,
  contextClientID: null,
  client: {
    client_id: null,
    client_name: '',
    client_fein: '',
    client_is_prospect: false,
    client_is_active: true,
    client_address: {
      client_address_line_1: '',
      client_address_line_2: '',
      client_address_city: '',
      client_address_state: '',
      client_address_zip: '',
    },
    client_contacts: []
  }
}

const storeState = (state) => {
  // exclude defined vars from local storage
  const { isInitialized, ...rest } = state ?? {}
  if (state?.client_id) {
    localStorage.clientState = JSON.stringify(rest)
  } else {
    delete localStorage.clientState
  }
}

const ClientReducer = (state, action) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        ...state,
        isInitialized: true,
        client: action.payload.client,
        clientExists: action.payload.clientExists || initialState.clientExists,
        clientLoaded: action.payload.clientLoaded || initialState.clientLoaded,
        contextClientID:
          action.payload.client?.client_id ||
          action.payload.client_id ||
          initialState.contextClientID,
      }
    case 'PURGE':
      delete localStorage.clientState
      return {
        ...initialState,
        isInitialized: true,
        clientExists: false,
        clientLoaded: true,
        contextClientID: action?.payload?.contextClientID || null,
      }
    case 'UPDATE_CLIENT':
      const { client } = action.payload
      return {
        ...state,
        client: client,
        contextClientID: client.client_id,
        clientLoaded: true,
        clientExists: true,
      }
    case 'STORE_STATE': {
      storeState(state)
      return state
    }
    default:
      return state
  }
}

const ClientContext = createContext(null)

function ClientProvider({ children }) {
  const [state, dispatch] = useReducer(ClientReducer, initialState)
  const { clientId } = useParams()
  const [clientID, setClientID] = useState(null)

  useEffect(() => {
    if (!clientId) {
      setClientID(null)
      return
    }
    let clientIDInt = parseInt(clientId)
    if (!isNaN(clientIDInt) && clientIDInt <= 0) {
      clientIDInt = NaN
    }
    if (clientID !== clientIDInt) {
      setClientID(clientIDInt)
    }
  }, [clientId, clientID])
  //const clientID = parseInt(clientId, 10)

  const updateClientContext = async ({ client }) => {
    if (!state?.isInitialized) {
      return
    } else if (clientID === null || isNaN(clientID)) {
      dispatch({
        type: 'PURGE',
      })
      return
    } else if (client?.client_id && client?.client_id !== clientID) {
      throw new Error('Cannot shift client context id via update')
    }
    // established that update information is in parity, and the state has been initialized
    try {
      if (clientID === state?.contextClientID && state?.clientLoaded) {
        if (state.clientExists) {
          if (client?.client_id) {
            dispatch({
              type: 'UPDATE_CLIENT',
              payload: {
                client,
              },
            })
          }
        } else {
          dispatch({
            type: 'PURGE',
            payload: {
              contextClientID: clientID,
            },
          })
        }
        return
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            ...state,
          },
        })
      }

      const res = await axios
        .get('/client/get', {
          params: { clientID: clientID, includeJoins: true },
        })
        .catch(async (errOrPromise) => {
          if (errOrPromise instanceof Error) {
            throw errOrPromise
          } else if (errOrPromise instanceof Promise) {
            return errOrPromise
          }
        })
      if (res.status === 200) {
        dispatch({
          type: 'UPDATE_CLIENT',
          payload: {
            ...state,
            client: res.data,
          },
        })
        return
      }
    } catch (err) {
      console.log(err)
    }
    dispatch({
      type: 'PURGE',
      payload: {
        contextClientID: clientID,
      },
    })
  }

  const clientUpdate = async (payload) => {
    if (!state?.isInitialized) {
      throw new Error('No Client In Context')
    } else if (state?.client?.client_id !== parseInt(clientId, 10)) {
      throw new Error('Cannot issue update for client out of context')
    }
    if (!payload) {
      throw new Error('Client update requires a payload')
    }
    if (!payload.client_id) {
      payload.client_id = state.client.client_id
    } else if (payload.client_id !== state.client.client_id) {
      throw new Error('Cannot update client that is not currently in context')
    }
    // assert that the joins will return
    if (!payload.client_address) {
      payload.client_address = {
        client_id: payload.client_id,
      }
    }
    const res = await axios
      .post('/client/update', payload)
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })
    // higher error codes do throw an error
    if (res.status !== 200) {
      throw new Error('Failed to update client: ' + res.statusText)
    }
    await updateClientContext({ client: res.data })
    return res.data
  }

  const clientContactDelete = async (clientContactID) => {
    if (!state?.isInitialized) {
      throw new Error('No client in context')
    } else if (state?.client?.client_id !== parseInt(clientId, 10)) {
      throw new Error('Cannot delete contact for client out of context')
    }
    if (!clientContactID) {
      throw new Error('Delete client contact requires clientContactID')
    }
    const res = await axios
      .delete(`/client/contact/delete`, {
        params: {
          clientContactID: clientContactID,
          clientID: state?.client?.client_id
        },
      })
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })
    if (res.status !== 200) {
      throw new Error('Failed to delete client contact: ' + res.statusText)
    }
    await updateClientContext({ client: res.data })
    return true
  }

  const clientNoteSearch = async (
    userID,
    createdDatetime,
    createdDatetimeAfter,
    createdDatetimeBefore,
    sort,
    sortOrder,
    limit,
    offset
  ) => {
    if (
      !state?.isInitialized ||
      !state?.clientExists ||
      !state.client?.client_id
    ) {
      return null
    }

    let searchTerms = {
      client_id: state.client.client_id,
    }
    if (userID) {
      searchTerms.user_id = userID
    }
    if (createdDatetime) {
      searchTerms.client_note_created_datetime = createdDatetime
    } else {
      if (createdDatetimeAfter) {
        searchTerms.client_note_created_datetime_after = createdDatetimeAfter
      }
      if (createdDatetimeBefore) {
        searchTerms.client_note_created_datetime_before = createdDatetimeBefore
      }
    }
    if (sort && typeof sort === 'object' && Object.keys(sort).length > 0) {
      searchTerms.sort = sort
    }
    if (Array.isArray(sortOrder) && sortOrder.length > 0) {
      searchTerms.sort_order = sortOrder
    }
    // 0 = no limit
    if (!isNaN(limit) && parseInt(limit, 10) >= 0) {
      searchTerms.limit = limit
    }
    if (!isNaN(offset) && parseInt(offset, 10) >= 0) {
      searchTerms.offset = offset
    }

    const res = await axios
      .post('/client/note/infoSearch', searchTerms)
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })
    // higher error codes do throw an error
    if (res.status !== 200) {
      throw new Error('Failed to load client note info: ' + res.statusText)
    }
    return res.data
  }

  const clientNoteCreate = async (
    userID,
    description,
    files,
    fileDescriptions
  ) => {
    if (
      !state?.isInitialized ||
      !state?.clientExists ||
      !state.client?.client_id
    ) {
      throw new Error('No client in context')
    }
    let payload = {
      client_id: state.client.client_id,
      user_id: userID,
      client_note_description: description,
    }

    const res = await axios
      .post('/client/note/create', payload)
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })
    if (res.status !== 200) {
      throw new Error('Failed to create new client note: ' + res.statusText)
    }

    if (Array.isArray(files) && files.length > 0) {
      try {
        const formData = new FormData()
        formData.append('clientNoteID', res.data.client_note_id)
        for (let i in files) {
          formData.append(`FileUpload_${i}`, files[i])
          if (Array.isArray(fileDescriptions) && fileDescriptions[i]) {
            formData.append(`FileUpload_${i}_Desc`, fileDescriptions[i])
          }
        }
        // build fromdata
        await axios
          .put(`/client/note/document/upload`, formData, {
            headers: {
              'content-type': 'multipart/form-data',
            },
          })
          .catch(async (errOrPromise) => {
            if (errOrPromise instanceof Error) {
              throw errOrPromise
            } else if (errOrPromise instanceof Promise) {
              return errOrPromise
            }
          })
      } catch (err) {
        console.log(err)
        let e = new Error(
          'Some (or all) documents failed to upload - but not was secure. Please make sure to go in and attach all valid files'
        )
        e.name = 'ConsultechFileUploadErr'
        throw e
      }
    }
    return true
  }

  const clientPolicySearch = async (
    clientPolicyNumber,
    clientPolicyEffectiveDatetime,
    clientPolicyEffectiveDatetimeAfter,
    clientPolicyEffectiveDatetimeBefore,
    clientPolicyExpirationDatetime,
    clientPolicyExpirationDatetimeAfter,
    clientPolicyExpirationDatetimeBefore,
    clientPolicyCreatedDatetime,
    clientPolicyCreatedDatetimeAfter,
    clientPolicyCreatedDatetimeBefore,
    sort,
    sortOrder,
    limit,
    offset
  ) => {
    if (
      !state?.isInitialized ||
      !state?.clientExists ||
      !state.client?.client_id
    ) {
      return null
    }

    let searchTerms = {
      client_id: state.client.client_id,
    }
    if (clientPolicyNumber) {
      searchTerms.client_policy_number = clientPolicyNumber
    }

    if (clientPolicyEffectiveDatetime) {
      searchTerms.client_policy_effective_datetime =
        clientPolicyEffectiveDatetime
    } else {
      if (clientPolicyEffectiveDatetimeAfter) {
        searchTerms.client_policy_effective_datetime_after =
          clientPolicyEffectiveDatetimeAfter
      }
      if (clientPolicyEffectiveDatetimeBefore) {
        searchTerms.client_policy_effective_datetime_before =
          clientPolicyEffectiveDatetimeBefore
      }
    }
    if (clientPolicyExpirationDatetime) {
      searchTerms.client_policy_expiration_datetime =
        clientPolicyExpirationDatetime
    } else {
      if (clientPolicyExpirationDatetimeAfter) {
        searchTerms.client_policy_expiration_date_after =
          clientPolicyExpirationDatetimeAfter
      }
      if (clientPolicyExpirationDatetimeBefore) {
        searchTerms.client_policy_expiration_date_before =
          clientPolicyExpirationDatetimeBefore
      }
    }
    if (clientPolicyCreatedDatetime) {
      searchTerms.client_policy_created_datetime = clientPolicyCreatedDatetime
    } else {
      if (clientPolicyCreatedDatetimeAfter) {
        searchTerms.client_policy_created_datetime_after =
          clientPolicyCreatedDatetimeAfter
      }
      if (clientPolicyCreatedDatetimeBefore) {
        searchTerms.client_policy_created_datetime_before =
          clientPolicyCreatedDatetimeBefore
      }
    }

    if (sort && typeof sort === 'object' && Object.keys(sort).length > 0) {
      searchTerms.sort = sort
    }
    if (Array.isArray(sortOrder) && sortOrder.length > 0) {
      searchTerms.sort_order = sortOrder
    }
    // 0 = no limit
    if (!isNaN(limit) && parseInt(limit, 10) >= 0) {
      searchTerms.limit = limit
    }
    if (!isNaN(offset) && parseInt(offset, 10) >= 0) {
      searchTerms.offset = offset
    }

    const res = await axios
      .post('/client/policy/infoSearch', searchTerms)
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })
    // higher error codes do throw an error
    if (res.status !== 200) {
      throw new Error('Failed to load client policy info: ' + res.statusText)
    }
    return res.data
  }

  const clientPolicyCreate = async (
    clientPolicyNumber,
    clientPolicyEffectiveDatetime,
    clientPolicyExpirationDatetime,
    fileFolder,
    files,
    fileDescriptions
  ) => {
    if (
      !state?.isInitialized ||
      !state?.clientExists ||
      !state.client?.client_id
    ) {
      throw new Error('No client in context')
    } else if (!clientPolicyNumber) {
      throw new Error('ClientPolicyNumber is required')
    }
    let payload = {
      client_id: state.client.client_id,
      client_policy_number: clientPolicyNumber,
    }

    if (clientPolicyEffectiveDatetime) {
      payload.client_policy_effective_datetime = clientPolicyEffectiveDatetime
    }

    if (clientPolicyExpirationDatetime) {
      payload.client_policy_expiration_datetime = clientPolicyExpirationDatetime
    }

    const res = await axios
      .post('/client/policy/create', payload)
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })
    if (res.status !== 200) {
      throw new Error('Failed to create new client policy: ' + res.statusText)
    }

    if (Array.isArray(files) && files.length > 0) {
      if (!fileFolder) {
        let e = new Error('Cannot upload files - File Folder was not provided')
        e.name = 'ConsultechFileUploadErr'
        throw e
      }
      try {
        const formData = new FormData()
        formData.append('clientPolicyID', res.data.client_policy_id)
        formData.append('clientPolicyDocumentFolder', fileFolder)
        for (let i in files) {
          formData.append(`FileUpload_${i}`, files[i])
          if (Array.isArray(fileDescriptions) && fileDescriptions[i]) {
            formData.append(`FileUpload_${i}_Desc`, fileDescriptions[i])
          }
        }
        // build fromdata
        await axios
          .put(`/client/policy/document/upload`, formData, {
            headers: {
              'content-type': 'multipart/form-data',
            },
          })
          .catch(async (errOrPromise) => {
            if (errOrPromise instanceof Error) {
              throw errOrPromise
            } else if (errOrPromise instanceof Promise) {
              return errOrPromise
            }
          })
      } catch (err) {
        console.log(err)
        let e = new Error(
          'Some (or all) documents failed to upload - but not was secure. Please make sure to go in and attach all valid files'
        )
        e.name = 'ConsultechFileUploadErr'
        throw e
      }
    }
    return true
  }

  const initialize = async () => {
    try {
      if (isNaN(clientID) || clientID === null) {
        dispatch({
          type: 'PURGE',
          payload: {
            contextClientID: clientID,
          },
        })
        return
      }
      const clientState = JSON.parse(
        localStorage.clientState ?? JSON.stringify(initialState)
      )
      if (
        clientState?.contextClientID !== null &&
        clientID === clientState?.contextClientID
      ) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            ...clientState,
          },
        })
        return
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            ...initialState,
          },
        })
        const res = await axios
          .get('/client/get', {
            params: { clientID: clientID, includeJoins: true },
          })
          .catch(async (errOrPromise) => {
            if (errOrPromise instanceof Error) {
              throw errOrPromise
            } else if (errOrPromise instanceof Promise) {
              return errOrPromise
            }
          })
        if (res.status === 200) {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              ...state,
              client: res.data,
              clientLoaded: true,
              clientExists: true,
            },
          })
          return
        }
      }
    } catch (err) {
      console.log(err)
      dispatch({
        type: 'PURGE',
        payload: {
          contextClientID: clientID,
        },
      })
    }
  }

  useEffect(initialize, [])
  useEffect(() => {
    updateClientContext({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientID])

  // store state in local storage whenever state changes in memory
  useEffect(() => {
    const asyncCallback = async () => {
      if (state.isInitialized) {
        dispatch({ type: 'STORE_STATE' })
      }
    }

    asyncCallback()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return (
    <ClientContext.Provider
      value={{
        ...state,
        updateClientContext,
        clientUpdate,
        clientContactDelete,
        clientNoteSearch,
        clientNoteCreate,
        clientPolicySearch,
        clientPolicyCreate,
      }}
    >
      {children}
    </ClientContext.Provider>
  )
}

export { ClientContext, ClientProvider }
