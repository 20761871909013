import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import React, { useCallback } from 'react'

const severities = {
  error: 'error',
  warning: 'warning',
  info: 'info',
  success: 'success',
}

const defaultHideDuration = null
function FlashFeedback({
  message,
  severity,
  open,
  setOpen,
  closeHandler,
  sx,
  autoHideDuration,
}) {
  const handleClose = useCallback(
    (event, reason) => {
      if (closeHandler) {
        closeHandler(event, reason)
      }
      setOpen(false)
    },
    [setOpen, closeHandler]
  )
  if (!message) {
    throw new Error('FlashFeedback must have message')
  }
  if (typeof open !== 'boolean') {
    throw new Error('FlashFeedback must have control boolean')
  }
  if (typeof setOpen !== 'function') {
    throw new Error('FlashFeedback setOpen must be a function')
  }
  if (closeHandler && typeof closeHandler !== 'function') {
    throw new Error('FlashFeedback closeHndler must be a function')
  }
  if (severity && !severities[severity]) {
    throw new Error(
      'FlashFeedback severity must be one of: "' +
        Object.keys(severities).join('", "') +
        '"'
    )
  }
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
      sx={sx || undefined}
      autoHideDuration={autoHideDuration || defaultHideDuration || undefined}
    >
      {severity ? (
        <Alert variant="filled" severity={severity}>
          {message}
        </Alert>
      ) : (
        <>{message}</>
      )}
    </Snackbar>
  )
}

export default FlashFeedback
