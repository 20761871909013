import React, { useState } from 'react'

import TextField from '@mui/material/TextField'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import MobileDatePicker from '@mui/lab/MobileDatePicker'

function DatePicker({
  date,
  setDate,
  label,
  inputFormat = 'M/dd/yyyy',
  inputProps,
  handleAccept,
}) {
  const handleChange = (newDate) => {
    setDate(newDate)
  }
  const [currentDate, setCurrentDate] = useState(date)

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        label={label}
        inputFormat={inputFormat}
        value={date}
        onClose={() => {
          if (date !== currentDate) {
            handleChange(currentDate)
          }
        }}
        onOpen={() => {
          setCurrentDate(date)
        }}
        onChange={handleChange}
        onAccept={handleAccept || null}
        renderInput={(params) => <TextField {...params} {...inputProps} />}
      />
    </LocalizationProvider>
  )
}

export default DatePicker
