import React, { useState } from 'react'
import { useFormikContext } from 'formik'

import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'

import ScrollableBox from 'components/common/ScrollableBox'

function ScrollableTermsField({ fieldData }) {
  const [scrolled, setScrolled] = useState(false)
  const { values, handleChange, errors } = useFormikContext()
  const { field, display, content, scrollable, inputProps, title, styles } =
    fieldData

  const showCheckbox = (scrollable && scrolled) || !scrollable

  const hasError = Boolean(errors[field])

  return (
    <>
      <Typography variant="h2" color={hasError && 'error.main'}>
        {title}
      </Typography>
      <ScrollableBox setScrolled={setScrolled} height={'540px'} {...styles}>
        {content}
      </ScrollableBox>
      <Fade in={showCheckbox}>
        <Box>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ my: 'auto', color: hasError && 'error.main' }}>
              {display}
            </Box>
            <Checkbox
              id={field}
              name={field}
              checked={values[field] ?? false}
              onChange={handleChange}
              sx={{ my: 'auto' }}
              {...inputProps}
            />
          </Box>
          {hasError && (
            <Box
              sx={{
                my: 'auto',
                color: 'error.main',
                fontWeight: 'bold',
              }}
            >
              {errors[field]}
            </Box>
          )}
        </Box>
      </Fade>
    </>
  )
}

export default ScrollableTermsField
