import React from 'react'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const theYear = new Date().getFullYear()

function Footer() {
  return (
    <Box component="footer">
      <Typography textAlign="center">
        Copyright © Consultech Insurance Brokerage LLC {theYear}
      </Typography>
    </Box>
  )
}

export default Footer
