import axios from 'utils/axios'

const useClientPolicyWorklog = () => {
  const clientPolicyWorklogGet = async (clientPolicyWorklogID) => {
    const res = await axios
      .get(`/client/policy/worklog/get`, {
        params: {
          clientPolicyWorklogID: clientPolicyWorklogID,
        },
      })
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })
    if (res.status !== 200) {
      throw new Error(res.statusText)
    }
    return res.data
  }

  const clientPolicyWorklogUpdate = async (
    clientPolicyWorklogID,
    clientPolicyWorklogDescription
  ) => {
    const res = await axios
      .post(`/client/policy/worklog/update`, {
        client_policy_worklog_id: clientPolicyWorklogID,
        client_policy_worklog_description: clientPolicyWorklogDescription,
      })
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })
    if (res.status !== 200) {
      throw new Error(res.statusText)
    }
    return res.data
  }

  return {
    clientPolicyWorklogGet,
    clientPolicyWorklogUpdate,
  }
}

export default useClientPolicyWorklog
