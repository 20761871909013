import React from 'react'
import { Navigate } from 'react-router-dom'

import { useParams } from 'react-router-dom'
import useClientContext from 'hooks/context/useClientContext'

function ClientGuard({ children }) {
  let { isInitialized, clientLoaded, clientExists, contextClientID } =
    useClientContext()
  const { clientId } = useParams()
  const clientID = parseInt(clientId, 10)
  if (isNaN(clientID) || clientID <= 0) {
    return <Navigate to="/console/dashboard" replace />
  }

  if (!isInitialized) {
    return <div />
  }

  if (!clientLoaded) {
    return <div />
  }

  if (
    clientLoaded &&
    !clientExists &&
    ((isNaN(contextClientID) && contextClientID !== null) ||
      contextClientID === clientID)
  ) {
    return <Navigate to="/console/dashboard" replace />
  }
  if (contextClientID !== clientID) {
    return <div />
  }

  return <>{children}</>
}

export default ClientGuard
