import React from 'react'
import { useFormikContext } from 'formik'

import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Box from '@mui/material/Box'

function RadioField({ fieldData }) {
  const { values, handleChange, errors } = useFormikContext()
  const { field, display, options, large, inputProps, callback } = fieldData

  const handleUpdateChange = ({ name, value }) => {
    handleChange({ target: { name, value } })

    if (callback) {
      callback(value, { values, handleChange })
    }
  }

  let PaperComponent = Box

  return (
    <FormControl
      component="fieldset"
      {...inputProps}
      error={Boolean(errors[field])}
    >
      <FormLabel component="legend">{display}</FormLabel>
      <RadioGroup
        row
        id={field}
        name={field}
        value={values[field] || ''}
        onChange={(e) =>
          handleUpdateChange({ name: field, value: e.target.value })
        }
      >
        {options?.map((option, i) => (
          <PaperComponent key={i} sx={{ mb: large ? 4 : 0, p: large ? 4 : 0 }}>
            <FormControlLabel
              key={`${field}-${option.value}`}
              value={option.value}
              control={<Radio />}
              label={option.display}
              disabled={option.isDisabled && option.isDisabled(values)}
            />
          </PaperComponent>
        ))}
      </RadioGroup>
      <FormHelperText>{errors[field]}</FormHelperText>
    </FormControl>
  )
}

export default RadioField
