import React from 'react'
import styled, { keyframes } from 'styled-components/macro'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

const upAndDown = keyframes`
  0%, 100% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(6px);
  }
`

const StyledTitleSection = styled(Box)`
  background-color: rgb(245, 245, 245);
  display: flex;
  height: 100vh;
  padding-top: 10vh;
  & > .MuiContainer-root {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
`

const DownIcon = styled(ArrowDownwardIcon)`
  animation: ${upAndDown} 2s linear infinite;
`

function TitleSection() {
  return (
    <StyledTitleSection>
      <Container maxWidth="md" sx={{ '& > .MuiTypography-root': { mb: 8 } }}>
        <Typography fontSize="64px" lineHeight="64px">
          Consultech Insurance Gateway
        </Typography>
        <Container maxWidth="sm">
          <Typography color="textSecondary" sx={{ mb: 16 }}>
            Insurance Lorem Ipsum Insurance!
          </Typography>
          <Typography fontWeight="medium">
          Consultech Insurance Lorem Ipsum Insurance!
          </Typography>
        </Container>
        <DownIcon sx={{ color: 'text.secondary' }} />
      </Container>
    </StyledTitleSection>
  )
}

export default TitleSection
