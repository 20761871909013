import React, { useState, useRef, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import DataTable from 'components/common/DataTable'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import ClientPolicyWorklogs from 'pages/console/Client/Policy/ClientPolicyWorklogs'

import FieldGroup from 'components/common/FormRenderer/FieldGroup'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import resolveErrorText from 'helpers/resolveErrorText'

//import useClientContext from 'hooks/context/useClientContext'
import useClientPolicyContext from 'hooks/context/useClientPolicyContext'

import { format, parseISO } from 'date-fns'

const clientPolicyDocumentFolders = [
  {
    display: 'Underwriting',
    value: 'Underwriting',
  },
  {
    display: 'Quote',
    value: 'Quote',
  },
  {
    display: 'Policy',
    value: 'Policy',
  },
  {
    display: 'Certificate',
    value: 'Certificate',
  },
  {
    display: 'Setup Info',
    value: 'Setup Info',
  },
  {
    display: 'Emails',
    value: 'Emails',
  },
  {
    display: 'NOC',
    value: 'NOC',
  },
  {
    display: 'Miscellaneous',
    value: 'Miscellaneous',
  },
]
const clientPolicyDocumentFolderOptions = [
  {
    display: 'Select a Folder',
    value: 'INVALID',
  },
  ...clientPolicyDocumentFolders,
]

const clientPolicyDocumentFolderFilters = [
  {
    display: 'Any',
    value: 'INVALID',
  },
  ...clientPolicyDocumentFolders,
]

// pagination limit = 0 means no limit
const paginationLimit = 0
function ClientPolicyDetail() {
  //const { client } = useClientContext()
  const {
    clientPolicy,
    clientPolicyDocumentDownload,
    clientPolicyDocumentSearch,
    clientPolicyDocumentUpload,
    clientPolicyDocumentDelete,
  } = useClientPolicyContext()
  const formikAddDocRef = useRef(null)

  const [loadingDocs, setLoadingDocs] = useState(false)
  const [updatingData, setUpdatingData] = useState(false)
  const [docError, setDocError] = useState(false)
  const [docDisplay, setDocDisplay] = useState([])
  const [docTotal, setDocTotal] = useState(0)
  const [docFileFilter, setDocFileFilter] = useState('INVALID')

  const [tzStr] = useState(
    <>
      &nbsp;
      {
        new Date()
          .toLocaleTimeString('en-us', {
            timeZoneName: 'short',
          })
          .split(' ')[2]
      }
    </>
  )

  const loadDocs = useCallback(async () => {
    if (clientPolicy?.client_policy_id) {
      try {
        setLoadingDocs(true)
        let res = await clientPolicyDocumentSearch(
          docFileFilter && docFileFilter === 'INVALID' ? '' : docFileFilter,
          null,
          ['client_policy_document_id'],
          paginationLimit,
          undefined
        )
        setDocDisplay(
          (res?.client_policy_documents || []).map((document) => {
            return {
              Description: document.client_policy_document_description || 'N/A',
              Folder: document.client_policy_document_folder,
              'File Name': document.client_policy_document_file_name,
              'File Type': document.client_policy_document_file_type,
              'File Size (KB)':
                document.client_policy_document_file_size / 1000,
              'Created Date': (
                <>
                  {format(
                    parseISO(document.client_policy_document_created_datetime),
                    'M/d/y h:mm a'
                  )}
                  {tzStr}
                </>
              ),
              '': (
                <Stack spacing={1} direction={{ xs: 'row' }}>
                  <Button
                    variant="contained"
                    disabled={updatingData}
                    onClick={() => {
                      clientPolicyDocumentDownload(
                        document.client_policy_document_id
                      )
                    }}
                  >
                    Download
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    disabled={updatingData}
                    onClick={() => {
                      setDeleteClientPolicyDocumentID(
                        document.client_policy_document_id
                      )
                      setDeleteClientPolicyDocumentModalOpen(true)
                    }}
                  >
                    Delete
                  </Button>
                </Stack>
              ),
            }
          })
        )
        setDocTotal(res.total)
      } finally {
        setLoadingDocs(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientPolicy, updatingData, docFileFilter])

  const [
    deleteClientPolicyDocumentModalOpen,
    setDeleteClientPolicyDocumentModalOpen,
  ] = useState(false)
  const [deleteClientPolicyDocumentID, setDeleteClientPolicyDocumentID] =
    useState(false)

  const deleteClientPolicyDocument = useCallback(
    async (clientPolicyDocumentID) => {
      setDeleteClientPolicyDocumentModalOpen(false)
      setUpdatingData(true)
      setDocError('')
      try {
        await clientPolicyDocumentDelete(clientPolicyDocumentID)
        await loadDocs()
      } catch (e) {
        setDocError(resolveErrorText(e))
      } finally {
        setUpdatingData(false)
      }
    },
    [
      loadDocs,
      setUpdatingData,
      setDeleteClientPolicyDocumentModalOpen,
      clientPolicyDocumentDelete,
    ]
  )

  useEffect(() => {
    loadDocs()
  }, [loadDocs, clientPolicy])

  const handleUploadDocs = useCallback(
    async (values, { setErrors }) => {
      try {
        if (!Array.isArray(values.files) || values.files.length === 0) {
          throw new Error('Files must be added to be uploaded')
        }
        if (
          Array.isArray(values.fileDescs) &&
          values.fileDescs.length !== values.files.length
        ) {
          throw new Error(
            'There should be as many file descriptions as there are files'
          )
        }
        if (!values.fileFolder || values.fileFolder === 'INVALID') {
          throw new Error('File Folder is required')
        }
        setDocError('')
        setUpdatingData(true)
        await clientPolicyDocumentUpload(
          values.fileFolder,
          values.files,
          values.fileDescs || null
        )
        try {
          await loadDocs()
          formikAddDocRef.current.resetForm()
        } catch (err) {
          setDocError(resolveErrorText(err))
        }
      } catch (err) {
        setDocError(resolveErrorText(err))
        setErrors({ submit: resolveErrorText(err) })
      } finally {
        setUpdatingData(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientPolicy, loadDocs]
  )

  return (
    <Box>
      <Paper
        sx={{ display: 'flex', flexDirection: 'column', padding: '0.5rem' }}
      >
        {clientPolicy ? (
          <>
            <Typography component="h3" variant="h3" mb={2}>
              <strong>Policy Details</strong>
            </Typography>
            <Table
              sx={{ minWidth: 700, mb: 4, p: 1 }}
              aria-label="customized table"
            >
              <TableBody>
                <TableRow>
                  <TableCell>Policy Number:</TableCell>
                  <TableCell>{clientPolicy.client_policy_number}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Effective Date:</TableCell>
                  <TableCell>
                    {clientPolicy.client_policy_effective_datetime
                      ? format(
                          parseISO(
                            clientPolicy.client_policy_effective_datetime
                          ),
                          'M/d/y'
                        )
                      : 'N/A'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Expiration Date:</TableCell>
                  <TableCell>
                    {clientPolicy.client_policy_expiration_datetime
                      ? format(
                          parseISO(
                            clientPolicy.client_policy_expiration_datetime
                          ),
                          'M/d/y'
                        )
                      : 'N/A'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Last Modified:</TableCell>
                  <TableCell>
                    {clientPolicy.client_policy_modified_datetime ? (
                      <>
                        {format(
                          parseISO(
                            clientPolicy.client_policy_modified_datetime
                          ),
                          'M/d/y h:mm a'
                        )}
                        {tzStr}
                      </>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Created:</TableCell>
                  <TableCell>
                    {clientPolicy.client_policy_created_datetime ? (
                      <>
                        {format(
                          parseISO(clientPolicy.client_policy_created_datetime),
                          'M/d/y h:mm a'
                        )}
                        {tzStr}
                      </>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Action:</TableCell>
                  <TableCell>
                    <Button
                      component={Link}
                      to={`/console/clients/${clientPolicy.client_id}/policies/${clientPolicy.client_policy_id}/edit`}
                      color="primary"
                      variant="contained"
                    >
                      Modify
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <hr />
            <Typography component="h3" variant="h3">
              <strong>Worklogs</strong>
            </Typography>
            <ClientPolicyWorklogs />
            <Box mt={4}>
              <Typography component="h3" variant="h3">
                <strong>Add Documents</strong>
              </Typography>
              {docError && <Alert severity="warning">{docError}</Alert>}
              {formikAddDocRef?.current?.errors?.submit && (
                <Alert severity="warning">
                  {formikAddDocRef?.current?.errors?.submit}
                </Alert>
              )}
              <Formik
                initialValues={{
                  fileFolder: 'INVALID',
                }}
                onSubmit={handleUploadDocs}
                innerRef={formikAddDocRef}
                validateOnChange={false}
                p={2}
                mb={4}
              >
                <Stack p={1}>
                  <Grid container spacing={2} mt={1}>
                    <Grid item container xs={12} sm={10}>
                      <Grid item xs={12}>
                        <FieldGroup
                          fieldData={{
                            field: 'fileFolder',
                            type: 'select',
                            fieldType: 'select',
                            options: clientPolicyDocumentFolderOptions,
                            InputProps: {
                              disabled: updatingData,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FieldGroup
                          fieldData={{
                            field: 'files',
                            descField: 'fileDescs',
                            display: '',
                            type: 'fileupload',
                            InputProps: {
                              disabled: loadingDocs || updatingData,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={10}></Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        disabled={loadingDocs || updatingData}
                        fullWidth={true}
                        color="primary"
                        variant="contained"
                        onClick={() => formikAddDocRef?.current?.submitForm()}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </Formik>
              <Box mt={4}></Box>
              <Dialog
                open={deleteClientPolicyDocumentModalOpen}
                onClose={() => {
                  setDeleteClientPolicyDocumentID(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  Delete Document
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this document? This action
                    cannot be undone. Do you wish to proceed?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setDeleteClientPolicyDocumentModalOpen(false)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      deleteClientPolicyDocument(deleteClientPolicyDocumentID)
                    }}
                    autoFocus
                  >
                    Delete Document
                  </Button>
                </DialogActions>
              </Dialog>
              <Grid container mb={3}>
                <Grid item xs={12} sm={3}>
                  Filter By Folder
                </Grid>
                <Grid item xs={12} sm={9}>
                  <TextField
                    type="select"
                    select={true}
                    fullWidth={true}
                    name="fileFilter"
                    size="small"
                    value={docFileFilter}
                    onChange={(_, opt) => {
                      if (opt?.props?.value !== docFileFilter) {
                        setDocFileFilter(opt.props.value)
                      }
                    }}
                  >
                    {clientPolicyDocumentFolderFilters?.map((option, idx) => (
                      <MenuItem key={idx} value={option.value}>
                        {option.display}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              {loadingDocs ? (
                <Typography component="h3" variant="muted">
                  Loading Documents....
                </Typography>
              ) : (
                <DataTable
                  titleBold={true}
                  title="Documents"
                  noRowsNode={
                    <Typography>Currently no attached documents</Typography>
                  }
                  paginationLimit={paginationLimit}
                  tableRows={docDisplay}
                  currentPage={0}
                  setCurrentPage={0}
                  totalResults={docTotal}
                />
              )}
            </Box>
          </>
        ) : null}
      </Paper>
    </Box>
  )
}

export default ClientPolicyDetail
