import React from 'react'

import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

import {
  mainListItems,
  userListItems,
  clientListItems,
  clientPolicyListItems,
} from './listItems'
import useAuth from 'hooks/context/useAuth'
import useClientContext from 'hooks/context/useClientContext'
import useClientPolicyContext from 'hooks/context/useClientPolicyContext'

const drawerWidth = 240

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(14),
    }),
  },
}))

function Sidebar({ open, toggleDrawer }) {
  const { user } = useAuth()
  const { client } = useClientContext()
  const { clientPolicy } = useClientPolicyContext()
  return (
    <>
      <Drawer
        sx={{
          maxWidth: '240px',
          overflowX: 'hidden',
        }}
        variant="permanent"
        open={open}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List>{mainListItems}</List>
        <Divider />
        {user?.isAdmin ? (
          <>
            <Divider />
            <List>{userListItems}</List>
          </>
        ) : null}
        {client?.client_id && (
          <>
            <Divider />
            <List>{clientListItems(client)}</List>
            {clientPolicy?.client_policy_id && (
              <>
                <Divider />
                <List>{clientPolicyListItems(client, clientPolicy)}</List>
              </>
            )}
          </>
        )}
      </Drawer>
    </>
  )
}

export default Sidebar
