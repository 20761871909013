import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Formik } from 'formik'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'

import FieldGroup from 'components/common/FormRenderer/FieldGroup'
import { Link } from 'react-router-dom'

import DataTable from 'components/common/DataTable'
import resolveErrorText from 'helpers/resolveErrorText'

import useClientContext from 'hooks/context/useClientContext'
import useClientPolicyContext from 'hooks/context/useClientPolicyContext'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FlashFeedback from 'components/common/FlashFeedback'

import { format, parseISO } from 'date-fns'

const paginationLimit = 50

function ClientPolicyWorklogs() {
  const { clientPolicy, clientPolicyWorklogSearch, clientPolicyWorklogCreate } =
    useClientPolicyContext()
  const { client } = useClientContext()
  const formikRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [policyWorklogPage, setPolicyWorklogPage] = useState(0)
  const [policyWorklogDisplay, setPolicyWorklogDisplay] = useState([])
  const [policyWorklogTotal, setPolicyWorklogTotal] = useState(0)
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const [
    createClientPolicyWorklogFormOpen,
    setCreateClientPolicyWorklogFormOpen,
  ] = useState(false)
  const [tzStr] = useState(
    <>
      &nbsp;
      {
        new Date()
          .toLocaleTimeString('en-us', {
            timeZoneName: 'short',
          })
          .split(' ')[2]
      }
    </>
  )

  const resetCreateClientPolicyWorklogForm = () => {
    formikRef.current.resetForm()
  }
  const initPolicyWorklogLookup = useCallback(async () => {
    if (client?.client_id) {
      setLoading(true)
      try {
        let res = await clientPolicyWorklogSearch(
          null,
          null,
          new Date().toISOString(),
          {
            client_policy_worklog_id: 'DESC',
          },
          undefined,
          paginationLimit,
          policyWorklogPage * paginationLimit
        )
        setPolicyWorklogDisplay(
          (res.client_policy_worklogs || []).map((policyWorklog) => {
            return {
              User: policyWorklog.user_name,
              Created: (
                <>
                  {format(
                    parseISO(
                      policyWorklog.client_policy_worklog_created_datetime
                    ),
                    'M/d/y h:mm a'
                  )}
                  {tzStr}
                </>
              ),
              Worklog: policyWorklog.client_policy_worklog_description,
              '': (
                <Button
                  component={Link}
                  to={
                    '/console/clients/' +
                    client.client_id +
                    '/policies/' +
                    clientPolicy.client_policy_id +
                    '/worklogs/' +
                    policyWorklog.client_policy_worklog_id
                  }
                  color="primary"
                  variant="contained"
                >
                  Manage
                </Button>
              ),
            }
          })
        )
        setPolicyWorklogTotal(res.total)
      } catch (err) {
        setError(resolveErrorText(err))
      } finally {
        setLoading(false)
      }
    }
  }, [
    client,
    clientPolicy,
    clientPolicyWorklogSearch,
    policyWorklogPage,
    tzStr,
  ])

  useEffect(() => {
    ;(async function () {
      await initPolicyWorklogLookup()
    })()
  }, [policyWorklogPage, client, clientPolicy, initPolicyWorklogLookup])

  const handleSubmit = async (values, { setErrors }) => {
    try {
      setLoading(true)
      if (!values?.client_policy_worklog_description?.length) {
        throw new Error('Worklogs must have a description')
      }
      await clientPolicyWorklogCreate(values.client_policy_worklog_description)

      if (policyWorklogPage === 0) {
        setPolicyWorklogTotal(policyWorklogTotal + 1)
      } else {
        setPolicyWorklogPage(0)
      }
      formikRef.current.resetForm()
      initPolicyWorklogLookup()
      setFeedbackOpen(true)
      resetCreateClientPolicyWorklogForm()
      setCreateClientPolicyWorklogFormOpen(false)
    } catch (err) {
      setErrors({ submit: resolveErrorText(err) })
    } finally {
      setLoading(false)
    }
  }

  return (
    client.client_id &&
    clientPolicy.client_policy_id && (
      <Box>
        <Paper
          sx={{ display: 'flex', flexDirection: 'column', padding: '.5em' }}
        >
          <FlashFeedback
            open={feedbackOpen}
            severity={'success'}
            setOpen={setFeedbackOpen}
            message={'Worklog Added Successfully'}
          />
          <Accordion
            expanded={createClientPolicyWorklogFormOpen}
            sx={{ border: '1px solid black' }}
          >
            <AccordionSummary
              onClick={() => {
                if (createClientPolicyWorklogFormOpen) {
                  resetCreateClientPolicyWorklogForm()
                }
                setCreateClientPolicyWorklogFormOpen(
                  !createClientPolicyWorklogFormOpen
                )
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography component="h3" variant="h3">
                <strong>Add Worklog</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {error && <Alert severity="warning">{error}</Alert>}
              <Formik
                initialValues={{
                  client_policy_worklog_description: '',
                }}
                onSubmit={handleSubmit}
                innerRef={formikRef}
              >
                <Stack mt={2} mb={2} p={1}>
                  {formikRef?.current?.errors?.submit && (
                    <Alert severity="warning">
                      {formikRef?.current?.errors?.submit}
                    </Alert>
                  )}
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12} sm={10}>
                      <FieldGroup
                        fieldData={{
                          field: 'client_policy_worklog_description',
                          display: 'Worklog...',
                          type: 'text',
                          InputProps: {
                            multiline: true,
                            required: true,
                            disabled: loading,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        disabled={loading}
                        fullWidth={true}
                        color="primary"
                        variant="contained"
                        onClick={() => formikRef?.current?.submitForm()}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </Formik>
            </AccordionDetails>
          </Accordion>
          <Box mb={4}></Box>
          {loading ? (
            <Typography component="h3" variant="h3">
              Loading
            </Typography>
          ) : client ? (
            <DataTable
              title=""
              titleBold={true}
              tableHeadStyling={{
                whiteSpace: 'nowrap',
              }}
              tableCellStyling={{
                Worklog: {
                  whiteSpace: 'pre-wrap',
                },
                core: {
                  whiteSpace: 'nowrap',
                },
              }}
              noRowsNode={<Typography>This policy has no worklogs</Typography>}
              paginationLimit={paginationLimit}
              tableRows={policyWorklogDisplay}
              currentPage={policyWorklogPage}
              setCurrentPage={setPolicyWorklogPage}
              totalResults={policyWorklogTotal}
            />
          ) : null}
        </Paper>
      </Box>
    )
  )
}

export default ClientPolicyWorklogs
