import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { Formik } from 'formik'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import DataTable from 'components/common/DataTable'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import FieldGroup from 'components/common/FormRenderer/FieldGroup'
import FlashFeedback from 'components/common/FlashFeedback'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import resolveErrorText from 'helpers/resolveErrorText'

import useClientNote from 'hooks/useClientNote'
import useUser from 'hooks/useUser'
import useClientContext from 'hooks/context/useClientContext'

import { format, parseISO } from 'date-fns'

// pagination limit = 0 means no limit
const paginationLimit = 0
function ClientNoteDetail() {
  const { client } = useClientContext()
  const {
    clientNoteGet,
    clientNoteUpdate,
    clientNoteDocumentDownload,
    clientNoteDocumentSearch,
    clientNoteDocumentUpload,
    clientNoteDocumentDelete,
  } = useClientNote()
  const { userGet } = useUser()
  const formikUpdateNoteRef = useRef(null)
  const formikAddDocRef = useRef(null)
  const { noteId } = useParams()

  const [loading, setLoading] = useState(false)
  const [loadingDocs, setLoadingDocs] = useState(false)
  const [updatingData, setUpdatingData] = useState(false)
  const [error, setError] = useState(false)
  const [docError, setDocError] = useState(false)
  const [user, setUser] = useState(null)
  const [note, setNote] = useState(null)
  //const [openAttachDocuments, setOpenAttachDocuments] = useState(false)
  const [docDisplay, setDocDisplay] = useState([])
  const [docTotal, setDocTotal] = useState(0)
  const [tzStr] = useState(
    <>
      &nbsp;
      {
        new Date()
          .toLocaleTimeString('en-us', {
            timeZoneName: 'short',
          })
          .split(' ')[2]
      }
    </>
  )
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const [
    deleteClientNoteDocumentModalOpen,
    setDeleteClientNoteDocumentModalOpen,
  ] = useState(false)
  const [deleteClientNoteDocumentID, setDeleteClientNoteDocumentID] =
    useState(false)

  const loadDocs = useCallback(async () => {
    if (note?.client_note_id) {
      try {
        setLoadingDocs(true)
        let res = await clientNoteDocumentSearch(
          note.client_note_id,
          null,
          ['client_note_document_id'],
          paginationLimit,
          undefined
        )
        setDocDisplay(
          (res?.client_note_documents || []).map((document) => {
            return {
              Description: document.client_note_document_description || 'N/A',
              'File Name': document.client_note_document_file_name,
              'File Type': document.client_note_document_file_type,
              'File Size (KB)': document.client_note_document_file_size / 1000,
              'Created Date': (
                <>
                  {format(
                    parseISO(document.client_note_document_created_datetime),
                    'M/d/y h:mm a'
                  )}
                  {tzStr}
                </>
              ),
              Actions: (
                <Stack spacing={1} direction={{ xs: 'row' }}>
                  <Button
                    variant="contained"
                    disabled={updatingData}
                    onClick={() => {
                      clientNoteDocumentDownload(
                        document.client_note_document_id
                      )
                    }}
                  >
                    Download
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    disabled={updatingData}
                    onClick={() => {
                      setDeleteClientNoteDocumentID(
                        document.client_note_document_id
                      )
                      setDeleteClientNoteDocumentModalOpen(true)
                    }}
                  >
                    Delete
                  </Button>
                </Stack>
              ),
            }
          })
        )
        setDocTotal(res.total)
      } finally {
        setLoadingDocs(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note, updatingData, tzStr])

  useEffect(() => {
    loadDocs()
  }, [note, loadDocs])

  const handleUploadDocs = useCallback(
    async (values, { setErrors }) => {
      try {
        if (!Array.isArray(values.files) || values.files.length === 0) {
          setDocError('Files must be added to be uploaded')
          return
        }
        if (
          Array.isArray(values.fileDescs) &&
          values.fileDescs.length !== values.files.length
        ) {
          setDocError(
            'There should be as many file descriptions as there are files'
          )
          return
        }
        setDocError('')
        setUpdatingData(true)
        await clientNoteDocumentUpload(
          note.client_note_id,
          values.files,
          values.fileDescs || null
        )
        try {
          await loadDocs()
        } catch (err) {
          setDocError(resolveErrorText(err))
        }
      } catch (err) {
        setDocError(resolveErrorText(err))
      } finally {
        setUpdatingData(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [note, loadDocs]
  )

  const deleteClientNoteDocument = useCallback(
    async (clientNoteDocumentID) => {
      setDeleteClientNoteDocumentModalOpen(false)
      setUpdatingData(true)
      setDocError('')
      try {
        await clientNoteDocumentDelete(clientNoteDocumentID)
        await loadDocs()
      } catch (e) {
        setDocError(resolveErrorText(e))
      } finally {
        setUpdatingData(false)
      }
    },
    [
      loadDocs,
      setUpdatingData,
      setDeleteClientNoteDocumentModalOpen,
      clientNoteDocumentDelete,
    ]
  )

  useEffect(() => {
    ;(async function () {
      if (noteId) {
        try {
          setLoading(true)
          setError(false)
          let loadedNote = await clientNoteGet(noteId)
          if (loadedNote) {
            setNote(loadedNote)
            let loadedUser = await userGet(loadedNote.user_id)
            if (loadedUser) {
              setUser(loadedUser)
            }
          }
        } catch (e) {
          setError(resolveErrorText(e) + ' -- please reload the page')
        } finally {
          setLoading(false)
        }
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUpdateNote = async (values, { setErrors }) => {
    try {
      setUpdatingData(true)
      if (
        !values.client_note_description ||
        typeof values.client_note_description !== 'string' ||
        values.client_note_description.length === 0
      ) {
        throw new Error('Note must be provided and have a length')
      }
      let updatedNote = await clientNoteUpdate(
        note.client_note_id,
        values.client_note_description
      )
      setNote(updatedNote)
      setFeedbackOpen(true)
      setTimeout(() => {
        window.location = `/console/clients/${client.client_id}/notes`
      }, 2000)
    } catch (err) {
      setErrors({ submit: resolveErrorText(err) })
      setUpdatingData(false)
    }
  }

  return (
    <Box>
      <FlashFeedback
        open={feedbackOpen}
        severity={'success'}
        setOpen={setFeedbackOpen}
        message={'Note Edited Successfully'}
      />
      <Dialog
        open={deleteClientNoteDocumentModalOpen}
        onClose={() => {
          setDeleteClientNoteDocumentID(false)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Document</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this document? This action cannot be
            undone. Do you wish to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteClientNoteDocumentModalOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              deleteClientNoteDocument(deleteClientNoteDocumentID)
            }}
            autoFocus
          >
            Delete Document
          </Button>
        </DialogActions>
      </Dialog>
      <Paper
        sx={{ display: 'flex', flexDirection: 'column', padding: '0.5rem' }}
      >
        {error && <Alert severity="warning">{error}</Alert>}
        <Typography component="h1" variant="h1" mb={4}>
          {client.client_name} Note #{noteId}
        </Typography>
        {loading ? (
          <Typography component="h2" variant="h2">
            Loading
          </Typography>
        ) : note ? (
          <>
            <Table
              sx={{ minWidth: 700, mb: 4, p: 1 }}
              aria-label="customized table"
            >
              <TableBody>
                <TableRow>
                  <TableCell>Note:</TableCell>
                  <TableCell style={{ whiteSpace: 'pre-wrap' }}>
                    {note.client_note_description}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>User:</TableCell>
                  <TableCell>{`${user.user_first_name} ${user.user_last_name} <${user.user_email}>`}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Last Modified:</TableCell>
                  <TableCell>
                    {note.client_note_modified_datetime ? (
                      <>
                        {format(
                          parseISO(note.client_note_modified_datetime),
                          'M/d/y h:mm a'
                        )}
                        {tzStr}
                      </>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Created:</TableCell>
                  <TableCell>
                    {note.client_note_created_datetime ? (
                      <>
                        {format(
                          parseISO(note.client_note_created_datetime),
                          'M/d/y h:mm a'
                        )}
                        {tzStr}
                      </>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <hr />
            <Typography component="h3" variant="h3" mb={2}>
              <strong>Modify Note</strong>
            </Typography>
            <Formik
              initialValues={{
                client_note_description: note.client_note_description,
              }}
              onSubmit={handleUpdateNote}
              innerRef={formikUpdateNoteRef}
              validateOnChange={false}
              p={2}
            >
              <Stack p={1}>
                {formikUpdateNoteRef?.current?.errors?.submit && (
                  <Alert severity="warning">
                    {formikUpdateNoteRef?.current?.errors?.submit}
                  </Alert>
                )}
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12} sm={10}>
                    <FieldGroup
                      fieldData={{
                        field: 'client_note_description',
                        display: 'Description',
                        type: 'text',
                        InputProps: {
                          multiline: true,
                          disabled: loading || updatingData,
                          required: true,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      disabled={loading || updatingData}
                      fullWidth={true}
                      color="primary"
                      variant="contained"
                      onClick={() => formikUpdateNoteRef?.current?.submitForm()}
                    >
                      Update Note
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </Formik>
            {loadingDocs ? (
              <Typography component="h2" variant="muted">
                Loading Documents....
              </Typography>
            ) : (
              <Box mt={4}>
                {docError && <Alert severity="warning">{docError}</Alert>}
                <Typography component="h3" variant="h3">
                  <strong>Add Documents</strong>
                </Typography>
                <Formik
                  initialValues={{}}
                  onSubmit={handleUploadDocs}
                  innerRef={formikAddDocRef}
                  validateOnChange={false}
                  p={2}
                >
                  <Stack p={1}>
                    {formikAddDocRef?.current?.errors?.submit && (
                      <Alert severity="warning">
                        {formikAddDocRef?.current?.errors?.submit}
                      </Alert>
                    )}
                    <Grid container spacing={2} mt={1}>
                      <Grid item xs={12} sm={10}>
                        <FieldGroup
                          fieldData={{
                            field: 'files',
                            descField: 'fileDescs',
                            display: '',
                            type: 'fileupload',
                            InputProps: {
                              disabled: loading || loadingDocs || updatingData,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={10}></Grid>
                      <Grid item xs={12} sm={2}>
                        <Button
                          disabled={loading || loadingDocs || updatingData}
                          fullWidth={true}
                          color="primary"
                          variant="contained"
                          onClick={() => formikAddDocRef?.current?.submitForm()}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Stack>
                </Formik>
                <DataTable
                  title="Documents"
                  titleBold={true}
                  noRowsNode={
                    <Typography>Currently no attached documents</Typography>
                  }
                  paginationLimit={paginationLimit}
                  tableRows={docDisplay}
                  currentPage={0}
                  setCurrentPage={0}
                  totalResults={docTotal}
                />
              </Box>
            )}
          </>
        ) : null}
      </Paper>
    </Box>
  )
}

export default ClientNoteDetail
