import React from 'react'
import { Navigate } from 'react-router-dom'

// layouts
import PublicFacingLayout from 'layouts/PublicFacing'
import ConsoleLayout from 'layouts/Console'
import AuthLayout from 'layouts/Auth'

// guards
import AuthGuard from 'components/guards/AuthGuard'
import LoginGuard from 'components/guards/LoginGuard'
import ClientGuard from 'components/guards/ClientGuard'
import ClientPolicyGuard from 'components/guards/ClientPolicyGuard'
import ConsoleGuestGuard from 'components/guards/ConsoleGuestGuard'

// pages
import Home from 'pages/Home'
// user pages

import Users from 'pages/console/Users'
import UserDetail from 'pages/console/UserDetail'

// Client Page
import Dashboard from 'pages/console/Dashboard'
import ClientDetail from 'pages/console/Client/ClientDetail'
import ClientNotes from 'pages/console/Client/ClientNotes'
import ClientNote from 'pages/console/Client/Note/ClientNote'
import ClientPolicies from 'pages/console/Client/ClientPolicies'
import ClientPolicy from 'pages/console/Client/Policy/ClientPolicy'
import ClientPolicyWorklogs from 'pages/console/Client/Policy/ClientPolicyWorklogs'
import ClientPolicyEdit from 'pages/console/Client/Policy/ClientPolicyEdit'
import ClientPolicyWorklog from 'pages/console/Client/Policy/Worklog/ClientPolicyWorklog'

// User login
// User login
import UserLogin from 'pages/console/UserLogin'
import UserLoginPage from 'pages/console/UserLogin/Login'
import UserLoginResetPassword from 'pages/console/UserLogin/ResetPassword'
import UserLoginMFA from 'pages/console/UserLogin/MFA'
import UserLoginMFACode from 'pages/console/UserLogin/MFACode'

// auth providers
import { AuthProvider } from 'contexts/AuthContext'

const routes = [
  {
    path: '/',
    element: <PublicFacingLayout />,
    children: [
      {
        path: '',
        element: <Home />,
      },
    ],
  },
  {
    path: '/console/login',
    element: (
      <AuthProvider>
        <AuthLayout>
          <ConsoleGuestGuard>
            <UserLogin />
          </ConsoleGuestGuard>
        </AuthLayout>
      </AuthProvider>
    ),
    children: [
      {
        path: '',
        element: (
          <LoginGuard>
            <UserLoginPage />
          </LoginGuard>
        ),
      },
      {
        path: 'reset-password',
        element: (
          <AuthGuard>
            <UserLoginResetPassword />
          </AuthGuard>
        ),
      },
      {
        path: 'challenge',
        children: [
          {
            path: 'auth-code',
            element: (
              <AuthGuard>
                <UserLoginMFACode />
              </AuthGuard>
            ),
          },
          {
            path: 'reset-password',
            element: (
              <AuthGuard>
                <UserLoginResetPassword />
              </AuthGuard>
            ),
          },
          {
            path: 'mfa-initialize',
            element: (
              <AuthGuard>
                <UserLoginMFA />
              </AuthGuard>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '/console',
    element: (
      <AuthProvider>
        <ConsoleLayout />
      </AuthProvider>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="/console/dashboard" />,
      },
      {
        path: 'dashboard',
        element: <Dashboard key="0" />,
      },
      {
        path: 'dashboard/clients/inactive',
        element: <Dashboard filter={{prospect:false, active:false}} key="1" />,
      },
      {
        path: 'dashboard/prospects/inactive',
        element: <Dashboard filter={{prospect:true, active:false}} key="2" />,
      },
      {
        path: 'users/:userId',
        element: <UserDetail />,
      },
      {
        path: 'users',
        element: <Users />,
      },
      {
        path: 'reset-password',
        element: (
          <AuthGuard>
            <UserLoginResetPassword />
          </AuthGuard>
        ),
      },
      {
        path: 'clients/:clientId/notes/:noteId',
        exact: true,
        element: (
          <ClientGuard>
            <ClientNote />
          </ClientGuard>
        ),
      },
      {
        path: 'clients/:clientId/notes',
        exact: true,
        element: (
          <ClientGuard>
            <ClientNotes />
          </ClientGuard>
        ),
      },
      {
        path: 'clients/:clientId/policies/:clientPolicyId/worklogs/:clientPolicyWorklogId',
        exact: true,
        element: (
          <ClientGuard>
            <ClientPolicyGuard>
              <ClientPolicyWorklog />
            </ClientPolicyGuard>
          </ClientGuard>
        ),
      },
      {
        path: 'clients/:clientId/policies/:clientPolicyId/worklogs',
        exact: true,
        element: (
          <ClientGuard>
            <ClientPolicyGuard>
              <ClientPolicyWorklogs />
            </ClientPolicyGuard>
          </ClientGuard>
        ),
      },
      {
        path: 'clients/:clientId/policies/:clientPolicyId/edit',
        exact: true,
        element: (
          <ClientGuard>
            <ClientPolicyGuard>
              <ClientPolicyEdit />
            </ClientPolicyGuard>
          </ClientGuard>
        ),
      },
      {
        path: 'clients/:clientId/policies/:clientPolicyId',
        exact: true,
        element: (
          <ClientGuard>
            <ClientPolicyGuard>
              <ClientPolicy />
            </ClientPolicyGuard>
          </ClientGuard>
        ),
      },
      {
        path: 'clients/:clientId/policies',
        exact: true,
        element: (
          <ClientGuard>
            <ClientPolicies />
          </ClientGuard>
        ),
      },
      {
        path: 'clients/:clientId',
        element: (
          <ClientGuard>
            <ClientDetail />
          </ClientGuard>
        ),
      },
    ],
  },
]

export default routes
