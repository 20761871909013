import axios from 'utils/axios'

const useClientNote = () => {
  const clientNoteGet = async (clientNoteID) => {
    const res = await axios
      .get(`/client/note/get`, {
        params: {
          clientNoteID: clientNoteID,
        },
      })
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })
    if (res.status !== 200) {
      throw new Error(res.statusText)
    }
    return res.data
  }

  const clientNoteDocumentSearch = async (
    clientNoteID,
    sort,
    sortOrder,
    limit,
    offset
  ) => {
    let parsedClientNoteID = parseInt(clientNoteID, 10)
    if (isNaN(parsedClientNoteID) || parsedClientNoteID <= 0) {
      throw new Error(
        'clientNoteID is a required parameter that is a positive integer'
      )
    }
    let searchTerms = {
      client_note_id: clientNoteID,
    }
    if (sort && typeof sort === 'object' && Object.keys(sort).length > 0) {
      searchTerms.sort = sort
    }
    if (Array.isArray(sortOrder) && sortOrder.length > 0) {
      searchTerms.sort_order = sortOrder
    }
    // 0 = no limit
    if (!isNaN(limit) && parseInt(limit, 10) >= 0) {
      searchTerms.limit = limit
    }
    if (!isNaN(offset) && parseInt(offset, 10) >= 0) {
      searchTerms.offset = offset
    }
    const res = await axios
      .post(`/client/note/document/search`, searchTerms)
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })
    if (res.status !== 200) {
      throw new Error(res.statusText)
    }
    return res.data
  }

  const clientNoteDocumentDownload = async (clientNoteDocumentID) => {
    // build fromdata
    await axios
      .get(`/client/note/document/download`, {
        params: { clientNoteDocumentID: clientNoteDocumentID },
        responseType: 'blob',
      })
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })
      .then((response) => {
        let filename = ''
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers['content-type'],
          })
        )
        let disposition = response.headers['content-disposition']

        if (disposition && disposition.indexOf('attachment') !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
            matches = filenameRegex.exec(disposition)

          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }

        let a = document.createElement('a')
        if (typeof a.download === 'undefined') {
          window.location.href = url
        } else {
          a.href = url
          a.download = filename
          document.body.appendChild(a)
          a.click()
          a.parentElement.removeChild(a)
        }
        return true
      })
    return true
  }

  const clientNoteUpdate = async (clientNoteID, clientNoteDescription) => {
    const res = await axios
      .post(`/client/note/update`, {
        client_note_id: clientNoteID,
        client_note_description: clientNoteDescription,
      })
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })
    if (res.status !== 200) {
      throw new Error(res.statusText)
    }
    return res.data
  }

  const clientNoteDocumentUpload = async (
    clientNoteID,
    files,
    fileDescriptions
  ) => {
    if (Array.isArray(files) && files.length > 0) {
      try {
        const formData = new FormData()
        formData.append('clientNoteID', clientNoteID)
        for (let i in files) {
          formData.append(`FileUpload_${i}`, files[i])
          if (Array.isArray(fileDescriptions) && fileDescriptions[i]) {
            formData.append(`FileUpload_${i}_Desc`, fileDescriptions[i])
          }
        }
        // build fromdata
        await axios
          .put(`/client/note/document/upload`, formData, {
            headers: {
              'content-type': 'multipart/form-data',
            },
          })
          .catch(async (errOrPromise) => {
            if (errOrPromise instanceof Error) {
              throw errOrPromise
            } else if (errOrPromise instanceof Promise) {
              return errOrPromise
            }
          })
      } catch (err) {
        console.log(err)
        let e = new Error(
          'Some (or all) documents failed to upload - but not was secure. Please make sure to go in and attach all valid files'
        )
        e.name = 'ConsultechFileUploadErr'
        throw e
      }
    }
    return true
  }

  const clientNoteDocumentDelete = async (clientNoteDocumentID) => {
    const res = await axios
      .delete(`/client/note/document/delete`, {
        params: {
          clientNoteDocumentID: clientNoteDocumentID,
        },
      })
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })
    // 404 is fine because that means the file doesn't exist anymore.
    if (res.status !== 204 && res.status !== 404) {
      throw new Error(res.statusText)
    }
    return true
  }

  return {
    clientNoteGet,
    clientNoteUpdate,
    clientNoteDocumentUpload,
    clientNoteDocumentDownload,
    clientNoteDocumentDelete,
    clientNoteDocumentSearch,
  }
}

export default useClientNote
