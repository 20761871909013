import { COLORSHADES } from 'constants/colors'

const components = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        backgroundColor: COLORSHADES.LIGHTGRAY[1],
        overflowX: 'hidden',
      },
    },
  },
}

export default components
