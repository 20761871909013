import axios from 'utils/axios'

const useUser = () => {
  const userGet = async (userID) => {
    try {
      const { data } = await axios
        .get(`/user/get`, {
          params: {
            userID: userID,
          },
        })
        .catch(async (errOrPromise) => {
          if (errOrPromise instanceof Error) {
            throw errOrPromise
          } else if (errOrPromise instanceof Promise) {
            return errOrPromise
          }
        })
      return data
    } catch (error) {
      // do something with error
      return null
    }
  }

  const userLoginHistoryGet = async (userID) => {
    try {
      const { data } = await axios
        .get(`/user/history/latestLogin`, {
          params: {
            userID: userID,
          },
        })
        .catch(async (errOrPromise) => {
          if (errOrPromise instanceof Error) {
            throw errOrPromise
          } else if (errOrPromise instanceof Promise) {
            return errOrPromise
          }
        })
      return data
    } catch (error) {
      // do something with error
      return null
    }
  }

  const userSearch = async (userName, sort, sortOrder, limit, offset) => {
    let payload = {}
    if (userName) {
      payload['user_name'] = userName
    }
    if (sort && typeof sort === 'object' && Object.keys(sort).length > 0) {
      payload['sort'] = sort
    }
    if (Array.isArray(sortOrder) && sortOrder.length > 0) {
      payload['sort_order'] = sortOrder
    }
    if (!isNaN(limit) && limit >= 0) {
      payload['limit'] = limit
    }
    if (!isNaN(offset) && offset >= 0) {
      payload['offset'] = offset
    }
    const { data } = await axios
      .post(`/user/searchPublic`, payload)
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })
    return data
  }

  const userUpdate = async (userPayload) => {
    const { data } = await axios
      .post(`/user/update`, userPayload)
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })
    return data
  }

  const userCreate = async (userPayload) => {
    const { data } = await axios
      .post(`/user/create`, userPayload)
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })
    return data
  }

  const userSetPasswordToTemp = async (userID) => {
    await axios
      .post('/user/setPasswordToTemp', { user_id: userID })
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })
  }

  const userClearMFA = async (userID) => {
    await axios
      .post('/user/clearmfa', { user_id: userID })
      .catch(async (errOrPromise) => {
        if (errOrPromise instanceof Error) {
          throw errOrPromise
        } else if (errOrPromise instanceof Promise) {
          return errOrPromise
        }
      })
  }

  return {
    userGet,
    userSearch,
    userCreate,
    userUpdate,
    userClearMFA,
    userSetPasswordToTemp,
    userLoginHistoryGet,
  }
}

export default useUser
